import React, { Component, PropTypes } from 'react';
import _ from 'lodash'
import CopyToClipboard from 'react-copy-to-clipboard'
import { notification, Spin, Table } from 'antd'
import ReactHighcharts from 'react-highcharts'
import HighchartsMore from 'highcharts-more'
HighchartsMore(ReactHighcharts.Highcharts)
require('../styles/Chart.scss')
const building = require('../images/building.png');
import { translateOffset } from '../actions/util'
import { CHART_CONFIG } from '../config/configPro'
const copy = require('../images/copy.png');

class Chart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false
    }
    this.emptyImg = true
  }

  convertNullToStr(v) {
    if (v == null || v == undefined) {
      return ''
    } else {
      return v
    }
  }

  compareWith(time) {
    let change = 'ctime'
    if (!(['密集宣传期', '全周期'].includes(time.group)) && (new Date(time)).getDay() == 1) {
      change = 'cweek'
    }
    return change
  }

  copy() {
    const { changeIdx, matrix, baseShow, halfWeek, cycleVs } = this.props
    const change = cycleVs.selected === 0 ? 'cweek' : 'ctime'//this.compareWith(time);

    let tmp_matrix = matrix;
    if (baseShow.enable && baseShow.selected === 0) {
      const tail = _.tail(matrix)
      const matrix_base = tail.map((row) => {
        return row.map((cell, idx) => {
          if (idx === 0) {
            return { value: cell.value + 'Base' }
          } else {
            const value = _.isNumber(cell.base) && cell.base < 50 ? (cell.base + '*') : cell.base
            return { value }
          }
        })
      })
      let content = []
      for (let i = 0; i < tail.length; i++) {
        content.push(tail[i])
        content.push(matrix_base[i])
      }
      tmp_matrix = [_.head(matrix)].concat(content)
    }
    if (changeIdx.enable) {
      switch (changeIdx.selected) {
        case 0:
          return tmp_matrix.map((ot, odx) => {
            return ot.map((t, idx) => {
              if (idx > 0) {
                if (odx == 0) {
                  return this.convertNullToStr(t.value) + '\t' + this.convertNullToStr(t.value) + '变化'
                } else {
                  return this.convertNullToStr(t.value) + '\t' + this.convertNullToStr(t[change])
                }
              }
              return this.convertNullToStr(t.value)
            }).join('\t')
          }).join('\n')
        case 2:
          return tmp_matrix.map((ot, odx) => {
            return ot.map((t, idx) => {
              if (idx > 0) {
                if (odx == 0) {
                  return this.convertNullToStr(t.value) + '变化'
                } else {
                  return this.convertNullToStr(t[change])
                }
              }
              return this.convertNullToStr(t.value)
            }).join('\t')
          }).join('\n')
        default:
          return tmp_matrix.map((ot) => {
            return ot.map((t) => this.convertNullToStr(t.value)).join('\t')
          }).join('\n')
      }
    }
    if (halfWeek.enable && halfWeek.selected === 1 && tmp_matrix.length > 0) {
      const out_idx = tmp_matrix[0].map((t, idx) => {
        return idx === 0 || _.ceil(t.alias) == t.alias
      })

      tmp_matrix = tmp_matrix.map((t) => {
        return t.filter((c, idx) => {
          return out_idx[idx];
        })
      })

    }

    return tmp_matrix.map((ot) => {
      return ot.map((t) => this.convertNullToStr(t.value)).join('\t')
    }).join('\n')
  }
  getCopyButton() {
    return (
      <div className='table-head'>
        <p className='text-left table-title'>{this.props.struct.title}</p>
        <div className="operate-btns">
          <div style={{ 'paddingLeft': '10px' }}>
            <CopyToClipboard text={this.copy()} onCopy={() => {
              this.setState({ copied: true })
              notification.info({
                message: '提示',
                description: '已复制到黏贴板',
                duration: 1
              })
            }}>
              <img src={copy} width='18' height='18' />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { chartShow, matrix, loading, showFormat, struct } = this.props

    let content = null
    if (chartShow) {
      if (matrix.length != 0) {
        this.emptyImg = false
      }
    }

    let format = 'table'
    const tabs = struct.disabled_tabs
    if (_.isArray(tabs) && !tabs.includes('showFormat') && showFormat.selected == 1) {
      format = 'spline'
    }

    return (
      <div>
        <Spin spinning={loading} style={{ 'background': 'white' }}>
          {!this.emptyImg && this.getCopyButton()}
          {content}
          {this.emptyImg && (
            <div className='empty-gif-container'>
              <img src={building} />
            </div>
          )}
          {format == 'table' ? this.table() : this.spline()}
        </Spin>
      </div>
    );
  }

  getLaunchdays(struct, matrix) {
    if (struct.y == 'movie_id') {
      let prev_launchday = null
      let rowIndexes = []
      // const tmp_matrix = _.sortBy(matrix.filter((t,idx)=> idx!=0), (t) => {
      //   if(t[0].launchday) {
      //     return -(new Date(t[0].launchday));
      //   }
      //   return 0;
      // });
      const launchdays = [{ value: '上映日期', color: '' }].concat(matrix.filter((t, idx) => idx != 0).map((t, idx) => {
        if (t[0].launchday != undefined) {
          if (prev_launchday != null) {
            const the_date = new Date(t[0].launchday)
            const day = the_date.getDay();
            const offset_day = Math.abs(the_date - prev_launchday) / (1000 * 60 * 60 * 24)
            if (offset_day > day || (offset_day < 7 - day && day < offset_day)) {
              prev_launchday = the_date;
              rowIndexes.push(idx)
            }
          } else {
            prev_launchday = new Date(t[0].launchday)
            rowIndexes.push(idx)
          }
          return { value: t[0].launchday, color: '' }
        }
        if (t[0].no_data) {
          return { value: '无数据', color: 'normals' }
        }
        if (t[0].offset == undefined) {
          return { value: '', color: 'normals' }
        }
        return { value: translateOffset(t[0].offset), color: 'normals' }
      }))
      return { launchdays, rowIndexes: _.slice(rowIndexes, 1, rowIndexes.length) }
    }
    return undefined
  }

  spline() {
    const { matrix, halfWeek, struct } = this.props
    let newMatrix = matrix
    if (newMatrix.length == 0) {
      return null
    }
    let half = false
    //隐藏半周数据
    if (_.isArray(struct.disabled_tabs) && !struct.disabled_tabs.includes('halfWeek') && halfWeek.selected == 1) {
      newMatrix = this.notIncludeHalf(newMatrix)
      half = true
    }

    const config = {
      xAxis: Object.assign({}, CHART_CONFIG.xAxis, {
        categories: newMatrix[0].filter((t) => {
          return t.value.includes('周')
        }).map((t) => t.value),
        tickInterval: half ? 1 : 2,
        plotLines: CHART_CONFIG.xAxis.plotLines.map((t) => {
          return Object.assign({}, t, {
            value: !half ? 8.5 : 4.25
          })
        })
      }),
      series: newMatrix.filter((t, idx) => idx != 0).map((t) => {
        const name = t[0].value

        const color = name.includes('均值') ? '#dcdcdc' : ''
        return {
          name,
          data: t.filter((tp, index) => index != 0).map((tp) => tp.value),
          color
        }
      })
    }

    return (
      <ReactHighcharts
        ref='charty'
        config={Object.assign({}, CHART_CONFIG, config)}
        className='chart'
      />
    )
  }

  notIncludeHalf(new_matrix) {
    if (new_matrix.length > 0) {
      const exclude_cycle = _.without(new_matrix[0].map((t, idx) => {
        if (t.value.includes('周')) {
          const offset = new Number(t.alias)
          if (Math.floor(offset) != offset) {
            return idx
          }
        }
        return null
      }), null)
      return new_matrix.map((t) => {
        return t.filter((v, idx) => {
          return !exclude_cycle.includes(idx)
        })
      })
    }
    return new_matrix
  }

  table() {
    const { matrix, changeIdx, cycleVs, struct, baseShow, halfWeek } = this.props

    const change_compare = cycleVs.selected == 0 ? 'cweek' : 'ctime'
    let newMatrix = matrix
    const launch_struct = this.getLaunchdays(struct, matrix)
    // console.log(launch_struct);
    let rowIndexes = []
    if (launch_struct != undefined) {
      const launchdays = launch_struct.launchdays
      rowIndexes = launch_struct.rowIndexes
      newMatrix = _.zip(launchdays, matrix)
      newMatrix = matrix.map((t, idx) => {
        if (launchdays[idx] != undefined) {
          return [launchdays[idx]].concat(t)
        }
        return [{ value: '', color: '', style: {} }].concat(t)
      })
    }
    //隐藏半周数据
    if (_.isArray(struct.disabled_tabs) && !struct.disabled_tabs.includes('halfWeek') && halfWeek.selected == 1) {
      newMatrix = this.notIncludeHalf(newMatrix)
    }

    if (newMatrix.length == 0) {
      return null
    } else {
      let len = parseInt(95 * 4 / 4)
      if (newMatrix[0].length <= 10) {
        len = parseInt(940 / (newMatrix[0].length))
      }
      const columns = newMatrix[0].map((t, idx) => {
        let fixed = false
        if (idx == 0 || (t.value && t.value.includes('/'))) {
          fixed = 'left'
        }

        if (t.value && t.value.includes('均值')) {
          fixed = 'right'
        }
        return {
          title: t.value,
          dataIndex: '' + idx,
          key: t.value,
          width: len,
          fixed,
          render: (data) => {
            return (
              <div className={'td-content ' + data.color} style={{ 'width': '100%', 'height': '100%' }}>
                {this.getCell(data, change_compare, changeIdx, baseShow)}
              </div>
            )
          }
        }
      })
      const dataSource = newMatrix.filter((t, idx) => idx != 0).map((t, idx) => {
        let obj = {}
        columns.map((cl, index) => {
          obj['' + index] = t[index]
        })
        obj['key'] = idx
        return obj
      })
      // console.log(dataSource);
      return (
        <div className="chart-table table-content">
          <Table
            scroll={{ x: len * columns.length }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowClassName={(record, index) => {
              if (rowIndexes.includes(index)) {
                return 'launch_sep'
              }
              return ''
            }}
          />
        </div>
      )
    }
  }

  roundFixed(v) {
    return _.round(v, 1)
  }
  displayValue(v) {
    return (v === null || v === undefined || v === '') ? (<span>&#8195;</span>) : v
  }

  getCell(f, change_compare, changeIdx, baseShow) {
    const color_addons = ['less', 'equal', 'more']
    let ch = this.convertNullToStr(f[change_compare]);
    let v = this.convertNullToStr(f.value);
    if (typeof v == 'number') {
      v = this.roundFixed(v)
    }
    let chidx = 0
    if (typeof ch == 'number') {
      ch = this.roundFixed(ch)
      if (ch > 0) {
        ch = '+' + ch
        chidx = 2
      } else if (ch == 0) {
        chidx = 1
      }
    }

    let value = (
      <span className='td-value'>
        {this.displayValue(v)}
      </span>
    )
    let normal_style = {}
    if (f.color == 'normals') {
      normal_style = { 'color': '#c3c3c3' }
    }
    if (typeof f.value == 'number' && changeIdx.enable) {
      switch (changeIdx.selected) {
        case 0:
          value = (
            <div className='td-both'>
              <div className='td-both-value' style={normal_style}>
                {this.displayValue(v)}
              </div>
              <div className={'td-both-change-' + color_addons[chidx]}>
                {this.displayValue(ch)}
              </div>
            </div>
          )
          break;
        case 2:
          if (f[change_compare] === null) {
            value = (
              <span className={'td-change-' + color_addons[chidx]}>
                &#8195;
              </span>
            )
          } else {
            value = (
              <span className={'td-change-' + color_addons[chidx]}>
                {this.displayValue(ch)}
              </span>
            )
          }
          break;
        default:
          break;
      }
    }
    let base_style = {}
    if (!(f.base == undefined || f.base == null) && f.base < 50) {
      base_style = { 'color': 'red' }
    }

    const base_showing = (baseShow.enable && baseShow.selected === 0)
    return (
      <div>
        {value}
        {base_showing && (
          <p className='base-value' style={base_style}>{(f.base == undefined || f.base == null) ? '' : ('(' + f.base + ')')}</p>
        )}
        {base_showing && _.isString(f.value) && (<span>&#8195;</span>)}
      </div>
    )
  }
}

Chart.propTypes = {
  matrix: PropTypes.array
}

Chart.defaultProps = {
  type: 'table',
  btws: {},
  changeIdx: { selected: 0, enable: false }
}


export default Chart;
