import 'core-js/fn/object/assign';
import React from 'react';
import ReactDOM from 'react-dom';
import store from './stores/Store'
import { Provider } from 'react-redux'
import MainComponent from './components/Main.js';
import { browserHistory } from 'react-router';
import { BrowserRouter, Route, Link } from "react-router-dom";


class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter >
          <Route path='/' component={MainComponent} />
          {/* <Route path='/generated' component={Main} /> */}
        </BrowserRouter>
      </Provider>
    );
  }
}

// Render the main component into the dom
ReactDOM.render(<App />, document.getElementById('app'));

