require('../styles/FilterOption.scss');
import React from 'react';
import { INDICATOR_GROUPS, DATE_GROUPS, CROWD_GROUPS, VALUE_CHANGE_GROUP, CYCLE_COMPARE_GROUP, HALF_WEEK_DISPLAY, BASE_GROUP, TABLE_FORMAT, unzipIndicator } from '../config/configPro'
import { selectFilm, selectCrowd, selectTime, selectIndicator, generate, ensureStruct, disableFilm, singleSelectFilm, expandCrowd, unselectNormal, selectNormal, updateSwitch, generateMarket, marketingListFetched, generateDiversion } from '../actions'
import { Affix } from 'antd'
import FilmIndex from './FilmIndex'
import { FilterButton, FilterCrowd, FilterIndi, FilterTime, FilterFilm, FilterNormal } from './Filters'
// import FilterObj from './Filters'
import { translateNormal, calcStruct, translate, isPostIndicator, isMarketing, calcMartketStruct } from '../actions/util'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import NormalTag from './NormalTag'

class FilterOption extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filmIndexVisible: false,
      normalTagVisible: false
    }
  }
  selectFilm(id) {
    const { platform, selectFilm } = this.props
    selectFilm(platform.film.filter((t) => t.id != id))
  }
  getActiveFilm(platform) {
    return platform.film.filter((t) => {
      return t.active
    })
  }
  previousDate(v, surveyList) {
    const idx = surveyList.map((t) => t.qdate).indexOf(v)
    if (surveyList.length > idx + 1) {
      return surveyList[idx + 1].qdate
    }
    return null
  }
  async fetchMarket(update_time) {
    const { platform, selectTime, generateMarket, marketingListFetched } = this.props;

    const options = _.pick(platform, ['film', 'time', 'indicator', 'crowd', 'normals']);
    const raw_option = translate(_.assign(options, {
      time: {
        value: '选择监测日期',
        group: '选择监测日期'
      }
    }));
    const indi = raw_option.indicators[0]
    const { struct, marketlist } = await calcMartketStruct(options);
    let new_time = platform.time

    if (update_time) {
      const ml = marketlist.filter((t) => t.category === indi);
      new_time = {
        value: ml.length > 0 ? ml[0].qdate : '选择监测日期',
        group: '选择监测日期'
      }
    }
    this.props.ensureStruct(struct);

    const option = {
      movie_id: raw_option.movie_ids[0],
      indicator: indi,
      crowds: raw_option.crowds,
      date: new_time.value
    };
    marketingListFetched(_.reverse(marketlist));
    selectTime(new_time);
    generateMarket(option);
  }
  async fetchDiversion(update_time) {
    const { platform, selectTime, generateDiversion } = this.props;
    const films = this.getActiveFilm(platform);
    const options = _.pick(platform, ['film', 'time', 'indicator', 'crowd', 'normals']);
    const raw_option = translate(options);
    const indi = raw_option.indicators[0]
    const { struct } = await calcMartketStruct(options);
    let new_time = platform.time;
    if (update_time) {
      const date_list = platform.surveyList.filter((t) => {
        return _.intersection(t.list, films.map((t) => t.id)).length > 0
      }).map((t) => t.qdate);
      new_time = {
        value: date_list[0],
        group: '选择监测日期'
      }
    }
    this.props.ensureStruct(struct);

    let is_date = new_time.group === '选择监测日期'
    let option = {
      movie_id: raw_option.movie_ids[0],
      indicator: indi,
      crowds: raw_option.crowds,
      tag_ids: platform.normals.map((t) => t.tags.map((tt) => tt.id))
    };
    selectTime(new_time);
    if (is_date) {
      option = _.assign(option, { date: new_time.value })
    } else {
      option = _.assign(option, { offset: new_time.value })
    }
    generateDiversion(option);
  }
  async fetchOther(update_time) {
    const { platform, selectTime } = this.props;
    let date_list = [], new_time = platform.time;

    const films = this.getActiveFilm(platform);
    const indi = platform.indicator;
    const film_len = films.length;
    if (update_time) {
      const indi_len = unzipIndicator(indi).length
      if (isPostIndicator(indi)) {
        new_time = {
          value: '1.0',
          group: '距离上映'
        }
      } else if (['常用热度指标', '转化率分析'].includes(indi[0].title) || (film_len > 1 && indi_len > 1)) {
        const surveyList = platform.surveyList

        date_list = surveyList.filter((t) => {
          return _.intersection(t.list, films.map((t) => t.id)).length > 0
        }).map((t) => t.qdate);

        new_time = {
          value: date_list[0],
          group: '选择监测日期'
        }
      } else {
        new_time = {
          value: '密集宣传期',
          group: '密集宣传期'
        }
      }
      selectTime(new_time);
    }
    const options = _.pick(platform, ['film', 'time', 'indicator', 'crowd', 'normals'])
    const gen_option = translate(_.assign(platform, { time: new_time }))
    let { struct, marketlist } = await calcStruct(Object.assign({}, options, { time: new_time }));

    this.props.marketingListFetched(_.reverse(marketlist));
    this.props.ensureStruct(struct);
    this.props.generate(gen_option);
  }
  //更新选项后请求数据
  async updateIt(update_time) {
    const { platform } = this.props;
    this.setState({ filmIndexVisible: false });
    const film_len = this.getActiveFilm(platform).length;
    const indi_title = platform.indicator[0].title
    const is_marketing = isMarketing(indi_title);
    if (film_len > 0) {
      if (is_marketing) {
        await this.fetchMarket(update_time);
      } else if (indi_title === '首选分流') {
        await this.fetchDiversion(update_time);
      } else {
        await this.fetchOther(update_time);
      }
    }
  }
  indicatorSelected(indi) {
    this.props.selectIndicator(indi)
  }
  calcOpacity(idx) {
    const { platform } = this.props
    const len = this.getActiveFilm(platform).length
    switch (idx) {
      case 1:
        return len > 0 ? 1 : 0.2
      case 2:
        return (len > 0 && platform.indicator.length > 0) ? 1 : 0.2
      case 3:
        return (len > 0 && platform.indicator.length > 0) ? 1 : 0.2
      default:
        return 0.2
    }
  }
  calcDateList(platform) {
    const indi = platform.indicator[0]
    if (isMarketing(indi.title)) {
      return platform.marketList.filter((t) => t.category === indi.alias).map((t) => t.qdate)
    }

    const surveyList = platform.surveyList
    const films = platform.film.map((t) => t.id)
    const survey_date = surveyList.filter((t) => {
      return _.intersection(t.list, films).length > 0
    }).map((t) => t.qdate)
    return survey_date
  }

  canCrowdSelectMulti(platform) {
    if (platform.indicator.length === 0) {
      return false;
    }
    const option = translate(platform)
    if (option.indicators.length > 1 && option.movie_ids.length > 1) {
      return false
    }
    if (option.indicators.length > 1 || option.movie_ids.length > 1) {
      if (option.offset == 'all' || option.offset == '-4~2') {
        return false
      }
    }
    return true
  }
  parseCrowdList(platform, multi_crowd) {
    if (multi_crowd) {
      let group_set = []
      CROWD_GROUPS.map((g) => {
        group_set.push(g.cn)
      })

      let list = []
      platform.crowd.map((t) => {
        if (!group_set.includes(t)) {
          list.push({ cn: t })
        }
      })
      if (list.length > 0) {
        const more = _.takeRight(CROWD_GROUPS)
        return [_.dropRight(CROWD_GROUPS).concat(list).concat(more)]
      }
    }
    return CROWD_GROUPS
  }
  parseNormalList(platform) {
    return platform.normals.map((t) => {
      return { cn: translateNormal(t) }
    })
  }
  delayUpdate(update_time) {
    setTimeout(() => {
      this.updateIt(update_time);
      // _.debounce(()=>{

      // },100)
    }, 100)
  }
  render() {
    const { platform, selectFilm, selectTime, selectCrowd, disableFilm, singleSelectFilm, expandCrowd, unselectNormal, selectNormal, updateSwitch } = this.props
    const { filmIndexVisible, normalTagVisible } = this.state

    const date_group = DATE_GROUPS.map((g) => {
      return g.map((gc) => {
        if (gc.cn == '选择监测日期') {
          return _.assign(gc, { options: this.calcDateList(platform) })
        }
        return gc
      })
    })
    const last_opacity = this.calcOpacity(3)
    const multi_crowd = this.canCrowdSelectMulti(platform)
    const filter_btns = [
      {
        title: '变化量',
        group: VALUE_CHANGE_GROUP,
        alias: 'changeIdx'
      }, {
        title: '半周',
        group: HALF_WEEK_DISPLAY,
        alias: 'halfWeek'
      }, {
        title: '计算周期',
        group: CYCLE_COMPARE_GROUP,
        alias: 'cycleVs'
      }, {
        title: 'BASE',
        group: BASE_GROUP,
        alias: 'baseShow'
      }, {
        title: '图示',
        group: TABLE_FORMAT,
        alias: 'showFormat'
      }
    ]

    const filter_show = _.isArray(platform.struct.disabled_tabs)

    return (
      <div className='filter-option-pro'>
        <div>
          <FilterFilm
            title='电影'
            opacity={1}
            groups={[platform.film.map((t) => {
              return _.assign(t, { type: 'checkbox' })
            })]}
            cb={() => this.setState({ filmIndexVisible: true })}
            select={(id) => { this.selectFilm(id); this.delayUpdate(true); }}
            disableIt={(id, active) => { disableFilm(id, active); this.delayUpdate(true) }}
            singleSelect={(id) => { singleSelectFilm(id); this.delayUpdate(true) }} />

          <Affix offsetBottom={0}>
            {filmIndexVisible && (
              <FilmIndex platform={platform} cb={selectFilm} close={() => this.delayUpdate(true)} />
            )}
          </Affix>
        </div>

        <div className='sep-line'></div>
        <FilterIndi title='指标'
          opacity={this.calcOpacity(1)}
          groups={INDICATOR_GROUPS}
          select={(indi) => { this.indicatorSelected(indi); this.delayUpdate(true); }}
          selected={platform.indicator.map((t) => t.title)}
          disabled_indi={platform.struct.disabled_indi || []} />

        <div className='sep-line'></div>
        <FilterTime
          title='时间'
          opacity={this.calcOpacity(2)}
          groups={date_group}
          select={(tm) => {
            selectTime(tm)
            setTimeout(() => {
              this.updateIt(false)
            }, 100)
          }}
          surveyList={platform.surveyList}
          selected={[platform.time.value]}
          disabled_time={platform.struct.disabled_time || []} />

        <div className='sep-line'></div>
        <FilterCrowd
          title='人群'
          opacity={last_opacity}
          groups={CROWD_GROUPS}
          select={(crowd) => {
            selectCrowd(crowd, !multi_crowd);
            this.delayUpdate(false)
          }}
          selected={platform.crowd}
          expandable={!multi_crowd}
          expandCrowd={expandCrowd}
          crowdMoreExpanded={platform.crowdMoreExpanded} />

        <div>
          <FilterNormal
            title='均值'
            opacity={last_opacity}
            groups={[this.parseNormalList(platform)]}
            cb={() => { this.setState({ normalTagVisible: true }) }}
            unselect={(n) => { unselectNormal(n); this.delayUpdate(false); }}
            select={(n) => { selectNormal(n); this.delayUpdate(false); }} />
          <Affix offsetBottom={0}>
            {normalTagVisible && (
              <NormalTag close={() => { this.setState({ normalTagVisible: false }); this.delayUpdate(false); }} />
            )}
          </Affix>
        </div>

        {filter_show && (
          <div className='filter-buttons'>
            {filter_btns.map((t, tidx) => {
              if ((platform.struct.disabled_tabs || []).includes(t.alias)) {
                return null
              }

              return (
                <FilterButton key={tidx} title={t.title} group={t.group} opacity={1} condition={platform[t.alias]} select={(idx) => { updateSwitch(t.alias, idx, true) }} />
              )
            })}
          </div>
        )}
      </div>
    );
  }
}

FilterOption.propTypes = {

};

function mapStateToProps(state) {
  return {
    platform: state.platform
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectFilm: bindActionCreators(selectFilm, dispatch),
    selectIndicator: bindActionCreators(selectIndicator, dispatch),
    selectTime: bindActionCreators(selectTime, dispatch),
    selectCrowd: bindActionCreators(selectCrowd, dispatch),
    generate: bindActionCreators(generate, dispatch),
    ensureStruct: bindActionCreators(ensureStruct, dispatch),
    disableFilm: bindActionCreators(disableFilm, dispatch),
    singleSelectFilm: bindActionCreators(singleSelectFilm, dispatch),
    expandCrowd: bindActionCreators(expandCrowd, dispatch),
    unselectNormal: bindActionCreators(unselectNormal, dispatch),
    selectNormal: bindActionCreators(selectNormal, dispatch),
    updateSwitch: bindActionCreators(updateSwitch, dispatch),
    generateMarket: bindActionCreators(generateMarket, dispatch),
    marketingListFetched: bindActionCreators(marketingListFetched, dispatch),
    generateDiversion: bindActionCreators(generateDiversion, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterOption)

