import React, {Component} from 'react';
import { Icon } from 'antd'

class FilterNormal extends Component {
  render() {
    const { title,opacity,groups,cb,select,unselect } = this.props
    
    let extra = null, style = {opacity}
    const disabled = opacity!=1
    style = {'marginTop':'0.4rem',opacity}
    extra = (
      <div className='add-normal' onClick={()=>{cb();}}>
        <Icon className='' style={{'marginRight':'4px'}} type={'plus'} />
        <span>添加均值</span>
      </div>
    )
    
    return (
      <div>
        <div className='filter-bar' style={style}>
          <p className='filter-bar-title'>{title}</p>
          <div className='filter-bar-choices'>
            {groups.map((group_choices,idx)=>{
              const style = (idx==(groups.length-1))?{}:{'borderRight':'1px solid #d9d9d9','marginRight':'0.4rem','paddingRight':'0.4rem'}
              const parsed_gc = group_choices.map((t)=>t.cn)
              return (
                <div key={idx} className='filter-bar-group' style={style}>
                  <div className={'filter-bar-btn '+(parsed_gc.includes('总体')?'filter-bar-btn-selected':'')} onClick={()=>{
                      if (parsed_gc.includes('总体')) {
                        unselect(parsed_gc.indexOf('总体'))
                      }else{
                        select({tags:[],count:''})
                      }
                    }}>
                    <div>
                      {'总体'}
                    </div>
                    <div style={{'marginRight':'0','paddingRight':'0','justifyContent':'flex-start'}}>
                      <Icon className='' style={{'marginLeft':'4px','fontSize':'1rem','marginRight':'0'}} type={'eye'} />
                    </div>
                  </div>
                  {group_choices.map((choice,index)=>{
                    if (choice==undefined || choice.cn=='总体') {
                      return null
                    }
                    return (
                      <div key={index} className={'filter-bar-btn filter-bar-btn-selected'} onClick={()=> {
                                            
                      }}>
                        <div>
                          {choice.cn}
                        </div>
                        <div style={{'marginRight':'0','paddingRight':'0','justifyContent':'flex-start'}} onClick={()=>{
                          if (disabled) return
                          unselect(index)//selectNormal({tags:[],count:''})
                        }}>
                          <Icon className='' style={{'marginLeft':'4px','fontSize':'1rem','marginRight':'0'}} type={'close'} />
                        </div>
                      </div>
                    )
                  })}
                  {extra}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default FilterNormal;