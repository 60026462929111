import * as AT from '../config/ActionTypes';
import axios from 'axios';
// axios.defaults.withCredentials = true
import { SERVER_IP, USER_HOST } from '../config/configPro';
import _ from 'lodash'
import { notification } from 'antd'

export function getUserInfo() {
  return (dispatch) => {
    axios.get(`${USER_HOST}current_user.json`, { withCredentials: true })
      .then((response) => {
        let res = response.data
        let isStaff = res.role.indexOf("staff") >= 0
        if (res.id === 'u' || res.id === 'r' || !isStaff) {
          notification.error({
            message: '错误',
            description: "角色验证失败",
            duration: 1
          })
          // 角色不符，退出登录
          dispatch(updateUserInfo(false))
          window.top.location.replace('https://sso.fanink.cn?redirect=https://iris.fanink.cn');
        } else {
          //更新用户信息
          dispatch(updateUserInfo(true))
        }
      }).catch((err) => {
        notification.error({
          message: '错误',
          description: err,
          duration: 1
        })
      });
  }
}

export function updateUserInfo(info) {
  return {
    type: AT.UPDATEUSERINFO,
    info
  }
}


export function fetchMarketingList() {
  return (dispatch) => {
    axios(`${SERVER_IP}api/with_filtered_questionary/`)
      .then((response) => {
        dispatch(marketingListFetched(response.data))
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取市场数据错误，方法:fetchMarketingList',
          duration: 1
        })
      });
  }
}

export function marketingListFetched(list) {
  return {
    type: AT.FETCH_MARKETINGLIST,
    list
  }
}

export function fetchFilmList() {
  return (dispatch) => {
    axios(`${SERVER_IP}api/collections/`)
      .then((response) => {
        dispatch(filmListFetched(response.data))
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取电影列表失败，方法:fetchFilmList',
          duration: 1
        })
      });
  }
}

function filmListFetched(rawdata) {
  const data = rawdata.map((t) => {
    const yy = (new Date(t.date)).getUTCFullYear() + ''
    return Object.assign({}, t, {
      tags: [...t.tags, yy]
    })
  })
  return {
    type: AT.FETCH_FILMLIST,
    filmList: data
  }
}

export function fetchSurveyList() {
  return (dispatch) => {
    axios(`${SERVER_IP}api/with_questionary/`)
      .then((response) => {
        dispatch(surveyListFetched(response.data))
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取监测列表失败，方法:fetchSurveyList',
          duration: 1
        })
      });
  }
}

function surveyListFetched(data) {
  return {
    type: AT.FETCH_SURVEYLIST,
    surveyList: data
  }
}


export function fetchNormalTags() {
  return (dispatch) => {
    axios(`${SERVER_IP}api/tags/`)
      .then((response) => {
        dispatch(normalTagsFetched(response.data))
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取均值标签失败，方法:fetchNormalTags',
          duration: 1
        })
      });
  }
}

function normalTagsFetched(tags) {
  return {
    type: AT.FETCH_TAGLIST,
    tagList: tags
  }
}

/*
* select films
* @param{Array of {name,id}} films 
*/
export function selectFilm(films) {
  return {
    type: AT.SELECT_FILM,
    films
  }
}

export function unselectFilm(fid) {
  return {
    type: AT.UNSELECT_FILM,
    fid
  }
}

export function singleSelectFilm(fid) {
  return {
    type: AT.SINGLE_SELECT_FILM,
    fid
  }
}

export function disableFilm(fid, able) {
  return {
    type: AT.DISABLE_FILM,
    fid,
    able
  }
}
/*
* select time
* @param{String} time 
*/
export function selectTime(time) {
  return {
    type: AT.SELECT_TIME,
    time
  }
}
/*
* select indicator
* @param{Array of String} indicator 
*/
export function selectIndicator(indicator) {
  return {
    type: AT.SELECT_INDICATOR,
    indicator
  }
}
/*
* @param{Array of String} crowd 
*/
export function selectCrowd(crowd, overwrite) {
  return {
    type: AT.SELECT_CROWD,
    crowd,
    overwrite
  }
}

export function expandCrowd() {
  return {
    type: AT.EXPAND_CROWD
  }
}

export function resetFilter() {
  return {
    type: AT.RESET_FILTER
  }
}

/*
* @param{Dictionary with keys {filmSelected,timeSelected,indicatorSelected,crowdSelected}} 
*/
export function generateDiversion(options) {
  return (dispatch) => {
    dispatch(generateDone(true))
    const col = axios({
      method: 'get',
      url: `${SERVER_IP}survey/collection2/`,
      params: _.omit(options, 'tag_ids')
    });

    const avg = axios({
      method: 'get',
      params: { indicators: ['interest_primary_convert'], crowds: ['overall'], tag_ids: JSON.stringify(options.tag_ids) },
      url: `${SERVER_IP}survey/averages/`
    })
    let ps = [col, avg]
    Promise.all(ps)
      .then((res) => {
        const points = res[0].data;
        const normal_points = res[1].data;
        dispatch(diversionGenerated(points, normal_points))
        dispatch(generateDone(false))
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取首选分流数据失败，方法:generateDiversion',
          duration: 1
        })
      });
  }
}

function diversionGenerated(points, normal_points) {
  return {
    type: AT.GENERATE_DIVERSION,
    points,
    normal_points
  }
}

export function generateMarket(options) {
  return (dispatch) => {
    dispatch(generateDone(true))
    const col = axios({
      method: 'get',
      url: `${SERVER_IP}survey/collection2/`,
      params: _.omit(options, 'tag_ids')
    })
    col.then((res) => {
      const points = res.data;
      const indi_top = _.range(1, 4).map((t) => options.indicator + '_' + t);
      const indi_cate = _.uniq(_.flatten(points.map((t) => t.categories))).map((t) => options.indicator + '_' + t + '_1');

      axios({
        method: 'get',
        url: `${SERVER_IP}survey/averages/`,
        params: { indicators: indi_top.concat(indi_cate), crowds: ['overall'], tag_ids: '[[]]' }
      }).then((res) => {
        const normal_points = res.data;
        dispatch(marketGenerated(points, normal_points));
        dispatch(generateDone(false));
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取营销均值数据失败，方法:generateMarket',
          duration: 1
        })
      })
    }).catch(() => {
      notification.error({
        message: '错误',
        description: '获取营销数据失败，方法:generateMarket',
        duration: 1
      })
    });
  }
}

function marketGenerated(points, normal_points) {
  return {
    type: AT.GENERATE_MARKET,
    points,
    normal_points
  }
}

export function generate(options) {
  return (dispatch) => {
    dispatch(generateDone(true))

    const col = axios({
      method: 'get',
      url: `${SERVER_IP}survey/collection/`,
      params: _.omit(options, 'tag_ids')
    })
    let ps = [col]
    if (true) {
      const avg = axios({
        method: 'get',
        params: Object.assign({}, _.omit(options, 'movie_ids', 'date', 'offset'), {
          crowds: ['overall']
        }),
        url: `${SERVER_IP}survey/averages/`
      })
      ps.push(avg)
    }

    Promise.all(ps)
      .then((res) => {
        const points = res[0].data
        const normal_points = res[1].data
        dispatch(generated(points, normal_points))
        dispatch(generateDone(false))
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取数据错误，方法:generate',
          duration: 1
        })
      });
  }
}
/*
* @param{Dictionary with keys {data,tabs}} 
*/
function generated(points, normal_points) {
  return {
    type: AT.GENERATE,
    points,
    normal_points
  }
}

export function ensureStruct(struct) {
  return {
    type: AT.ENSURE_STRUCT,
    struct
  }
}

export function selectNormal(normal) {
  return {
    type: AT.SELECT_NORMAL,
    normal
  }
}

export function unselectNormal(idx) {
  return {
    type: AT.UNSELECT_NORMAL,
    idx
  }
}

export function countTagsOfFilm(tags) {
  const tmp = { tag_ids: tags }
  return (dispatch) => {
    axios({
      method: 'get',
      params: tmp,
      url: `${SERVER_IP}movie_count/`
    })
      .then((response) => {
        const rd = response.data
        dispatch(tagsOfFilmCounted(rd.count || 0, tags))
      }).catch(() => {
        notification.error({
          message: '错误',
          description: '获取标签电影数量错误，方法:countTagsOfFilm',
          duration: 1
        })
      });
  }
}

function tagsOfFilmCounted(count, tags) {
  return {
    type: AT.COUNT_TAGS_OF_FILM,
    count,
    tags
  }
}


function generateDone(done) {
  return {
    type: AT.GENERATE_DONE,
    done
  }
}

export function updateSwitch(switch_type, selected, enable) {
  return {
    type: AT.UPDATE_SWITCH,
    switch_type,
    selected,
    enable
  }
}