import React, { Component, PropTypes } from 'react';
// import PropTypes from 'prop-types';
import { Icon, Popover } from 'antd'
import { TRACKING_CYCLE_OFFSET, TRACKING_CYCLE } from '../../config/configPro'

class FilterTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      option: null
    }
  }

  previousDate(v) {
    const { surveyList } = this.props
    const idx = surveyList.map((t) => t.qdate).indexOf(v)
    return surveyList[idx + 1].qdate || null
  }

  render() {
    const { title, opacity, groups, select, selected } = this.props
    let style = { opacity }
    const disabled = opacity != 1
    const { option } = this.state

    return (
      <div>
        <div className='filter-bar' style={style}>
          <p className='filter-bar-title'>{title}</p>
          <div className='filter-bar-choices'>
            {groups.map((group_choices, idx) => {
              const style = (idx == (groups.length - 1)) ? {} : { 'borderRight': '1px solid #d9d9d9', 'marginRight': '0.4rem', 'paddingRight': '0.4rem' }
              return (
                <div key={idx} className='filter-bar-group' style={style}>
                  {group_choices.map((choice) => {
                    if (choice == undefined) {
                      return null
                    }
                    return this.renderChoice(choice, select, disabled, selected, option)
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  renderChoice(choice, select, disabled, selected, option) {
    const { disabled_time } = this.props
    const choice_disabled = disabled || disabled_time.includes(choice.cn)
    const style = choice_disabled ? { opacity: 0.4 } : {}

    switch (choice.type) {
      case 'select':
        {
          const content = (
            <div className='monitor-date-list'>
              {choice.options.map((d, didx) => {
                return (
                  <p key={didx} className='text-center date-in-list pointer' onClick={() => {
                    if (choice.cn == '距离上映') {
                      select({ value: TRACKING_CYCLE_OFFSET[didx], group: choice.cn })
                    } else {
                      select({ value: d, group: choice.cn })
                    }
                    this.setState({ option: '' });
                  }}>
                    {d}
                  </p>
                )
              })}
            </div>
          );
          let group_title = choice.cn, selected_cls = false
          if (choice.cn == '距离上映' && TRACKING_CYCLE_OFFSET.includes(selected[0])) {
            const idx = TRACKING_CYCLE_OFFSET.indexOf(selected[0])
            group_title = TRACKING_CYCLE[idx]
            selected_cls = true
          } else {
            if (choice.options.includes(selected[0])) {
              group_title = selected[0]
              selected_cls = true
            }
          }
          return (
            <div key={choice.cn} style={style}>
              <Popover trigger={'click'} placement={'bottom'} content={content} onVisibleChange={() => { this.setState({ option: choice.cn == option ? '' : choice.cn }); }} visible={!choice_disabled && option == choice.cn}>
                <div className={'filter-bar-btn' + ((selected.includes(choice.cn) || selected_cls) ? ' filter-bar-btn-selected' : '')} onClickCapture={(e) => {
                  if (choice_disabled) e.stopPropagation()
                }}>
                  <span>{group_title}</span>
                  <Icon className='' style={{ 'marginLeft': '4px', 'marginTop': '2px' }} type={'down'} />
                </div>
              </Popover>
            </div>
          )
        }
      default:
        return (
          <div key={choice.cn} className={'filter-bar-btn' + (selected.includes(choice.cn) ? ' filter-bar-btn-selected' : '')} style={style} onClick={() => {
            if (choice_disabled) return
            select({ value: choice.cn, group: choice.cn })
          }}>
            {choice.cn}
          </div>
        )
    }
  }
}

FilterTime.propTypes = {
  opacity: PropTypes.number,
  title: PropTypes.string,
  choices: PropTypes.array
};

export default FilterTime;