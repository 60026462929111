import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectNormal, countTagsOfFilm } from '../actions'
import '../styles/NormalTag.scss'
import _ from 'lodash'
import { Modal, Button, Row, Col, Icon } from 'antd';

class NormalTag extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: []
    }
  }
  isExist() {
    const { platform } = this.props
    const { tags } = this.state

    const same = platform.normals.filter((t) => {
      if (t.tags != undefined && t.tags.length == tags.length && _.intersection(tags.map((tt) => tt.id), t.tags.map((tt) => tt.id)).length == t.tags.length) {
        return true
      }
      return false
    })
    return same.length == 1
  }

  render() {
    const { selectNormal, platform, countTagsOfFilm, close } = this.props
    const { tags } = this.state
    const rows = _.chunk(platform.tagList, 6)

    return (
      <div className='normal-tag'>
        <div style={{ 'height': '1.8rem' }}>
          <Icon className='pull-right' style={{ 'marginRight': '0px' }} type={'close'} onClick={() => close()} />
        </div>
        <div className='tags-playground'>
          {rows.map((t, tidx) => {
            let row = t
            if (t.length != 6) {
              row = row.concat(_.fill(Array(6 - t.length), { id: null, name: null }))
            }
            return (
              <Row className='' key={tidx} style={{ 'width': '100%' }} type={'flex'} justify={'space-around'}>
                {row.map((tt, idx) => {
                  const tag = tt
                  if (tag.id == null) {
                    return (
                      <Col key={idx} span={4} className={'tag-item'} >
                        <span></span>
                      </Col>
                    )
                  }
                  const selected = tags.filter((ts) => tag.id == ts.id).length > 0
                  return (
                    <Col span={4} className={'tag-item' + (selected ? ' selected' : '')} key={idx} onClick={() => {
                      if (selected) {
                        this.setState({ tags: tags.filter((tss) => tag.id != tss.id) });
                      } else {
                        this.setState({
                          tags: [...tags, {
                            id: tag.id,
                            name: tag.name
                          }]
                        });
                      }
                    }}>
                      <span>{tag.name}</span>
                    </Col>
                  )
                })}
              </Row>
            )
          })}
        </div>
        <div className='operate-tag'>
          <Button type={'primary'} className='generate-tag' onClick={() => {
            if (tags.length > 0) {
              if (!this.isExist()) {
                selectNormal({ tags, count: '计算中...' })
                countTagsOfFilm(tags.map((t) => t.id))
                this.setState({ tags: [] });
              } else {
                Modal.info({
                  title: '提示',
                  content: (
                    <div>
                      <p>标签集合已存在</p>
                    </div>
                  ),
                  okText: '确定'
                });
              }
            }
          }}>生成标签</Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    platform: state.platform
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectNormal: bindActionCreators(selectNormal, dispatch),
    countTagsOfFilm: bindActionCreators(countTagsOfFilm, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NormalTag)