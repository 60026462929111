import {
  createStore,
  applyMiddleware
} from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise';
// import createLogger from 'redux-logger';
import rootReducer from '../reducers'

// const logger = createLogger();
const store = createStore(rootReducer, {}, applyMiddleware(
  thunk,
  // logger,
  promise
))
// , logger
// store.subscribe(() => {
//   console.log(store.getState());
// })

export default store