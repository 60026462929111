import * as AT from '../config/ActionTypes';
import { combineReducers } from 'redux';
import { generateMatrix, generateMarketMatrix, generateDiversionMatrix } from '../actions/util';
import { INDICATOR_GROUPS } from '../config/configPro';
import _ from 'lodash';

const TABS = {
  changeIdx: {
    selected: 0,
    enable: true
  },
  cycleVs: {
    selected: 0,
    enable: true
  },
  baseShow: {
    selected: 1,
    enable: true
  },
  halfWeek: {
    selected: 0,
    enable: true
  },
  showFormat: {
    selected: 0,
    enable: true
  }
}
const default_indi = [INDICATOR_GROUPS[0][0]];
const initial = {
  login: false,
  filmList: [],
  surveyList: [],
  tagList: [],
  marketList: [],
  //,{active:true,id:737,launchday:'2016-10-14',name:'圆梦巨人',type:'checkbox'},{active:true,id:724,launchday:'2016-11-18',name:'我不是潘金莲',type:'checkbox'},{active:true,id:737,launchday:'2016-10-14',name:'圆梦巨人',type:'checkbox'}
  film: [],
  time: { value: '密集宣传期', group: '密集宣传期' },
  indicator: default_indi,
  crowd: ['总体'],
  normals: [{ tags: [], count: '' }],
  loading: false,
  generated: false,
  normalsGenerated: false,
  points: [],
  struct: {},
  matrix: [],
  tabs: [],
  rawPoints: [],
  rawNormalPoints: [],
  crowdMoreExpanded: false,
  ...TABS
}

function platform(state = initial, action) {
  switch (action.type) {
    case AT.UPDATEUSERINFO:
      {
        return merge(state, {
          login: action.info
        })
      }
    case AT.FETCH_MARKETINGLIST:
      {
        return merge(state, {
          marketList: action.list
        })
      }
    case AT.UPDATE_SWITCH:
      {
        let obj = {}
        obj[action.switch_type] = {
          selected: action.selected,
          enable: action.enable
        }
        if (['密集宣传期', '全周期'].includes(state.time.group)) {
          obj['changeIdx'] = { selected: 0, enable: false }
          obj['cycleVs'] = { selected: 0, enable: false }
        }

        return merge(state, obj)
      }
    case AT.GENERATE_DONE:
      return merge(state, {
        loading: action.done
      })
    case AT.CLEAR_RAWPOINTS:
      {
        return merge(state, {
          rawPoints: [],
          rawNormalPoints: [],
          generated: false,
          normalsGenerated: false
        })
      }
    case AT.EXPAND_CROWD:
      return merge(state, {
        crowdMoreExpanded: true
      })
    case AT.ENSURE_STRUCT:
      let new_tabs = TABS
      for (var key in new_tabs) {
        if (new_tabs.hasOwnProperty(key)) {
          if (action.struct.disabled_tabs.includes(key)) {
            new_tabs[key].enable = false
          } else {
            new_tabs[key].enable = true
          }
        }
      }
      return merge(state, {
        ...new_tabs,
        struct: action.struct
      })
    case AT.GENERATE:
      {
        const { matrix } = generateMatrix(action.points, action.normal_points, state)
        return merge(state, {
          rawPoints: action.points,
          rawNormalPoints: action.normal_points,
          generated: true,
          matrix
        })
      }
    case AT.GENERATE_MARKET:
      {
        const { matrix } = generateMarketMatrix(action.points, action.normal_points, state)
        return merge(state, {
          rawPoints: action.points,
          rawNormalPoints: action.normal_points,
          generated: true,
          matrix
        })
      }
    case AT.GENERATE_DIVERSION:
      {
        const { matrix } = generateDiversionMatrix(action.points, action.normal_points, state)
        return merge(state, {
          rawPoints: action.points,
          rawNormalPoints: action.normal_points,
          generated: true,
          matrix
        })
      }
    case AT.RESET_FILTER:
      return merge(state, {
        film: [],
        time: { value: '密集宣传期', group: '密集宣传期' },
        indicator: ['awareness'],
        crowd: ['overall'],
        normals: [{ tags: [], count: '' }],
        rawPoints: [],
        rawNormalPoints: [],
        generated: false,
        normalsGenerated: false
      })
    case AT.FETCH_FILMLIST:
      const group = _.groupBy(action.filmList, (n) => n.launch_to_monday)
      let list = []
      for (var key in group) {
        if (group.hasOwnProperty(key)) {
          let one = { date: key, list: [] }
          group[key].map((t) => {
            one.list.push(t)
          })
          list.push(one)
        }
      }
      return merge(state, {
        filmList: list || []
      })
    case AT.FETCH_TAGLIST:
      return merge(state, {
        tagList: action.tagList
      })
    case AT.FETCH_SURVEYLIST:
      return merge(state, {
        surveyList: action.surveyList
      })
    case AT.SELECT_FILM:
      return merge(state, {
        film: action.films,
        crowd: newCrowd(action.films),
        indicator: newIndicator(state.indicator, action.films)
      })
    case AT.UNSELECT_FILM:
      const film = state.film.filter((t) => {
        return t.id != action.fid
      })
      return merge(state, {
        film,
        crowd: newCrowd(action.films),
        indicator: newIndicator(state.indicator, film)
      })
    case AT.DISABLE_FILM:
      {
        const film = state.film.map((t) => {
          if (t.id == action.fid) {
            return merge(t, { active: action.able })
          }
          return t
        })
        return merge(state, {
          film,
          crowd: newCrowd(film),
          indicator: newIndicator(state.indicator, film)
        })
      }
    case AT.SINGLE_SELECT_FILM:
      {
        const film = state.film.map((t) => {
          if (t.id == action.fid) {
            return merge(t, { active: true })
          } else {
            return merge(t, { active: false })
          }
        })

        return merge(state, {
          film,
          crowd: newCrowd(film),
          indicator: newIndicator(state.indicator, film)
        })
      }
    case AT.SELECT_TIME:
      return merge(state, {
        time: action.time
      })
    case AT.SELECT_INDICATOR:
      return merge(state, {
        indicator: [action.indicator],
        crowd: newCrowd(state.film)
      })
    case AT.SELECT_CROWD:
      {
        if (action.overwrite) {
          return merge(state, {
            crowd: [action.crowd],
            generated: false,
            normalsGenerated: false
          })
        } else {
          if (!state.crowd.includes(action.crowd)) {
            return merge(state, {
              crowd: [...state.crowd, action.crowd]
            })
          } else {
            if (state.crowd.length == 1) {
              return state
            }
            return merge(state, {
              crowd: state.crowd.filter((t) => {
                if (t != action.crowd) {
                  return t
                }
              })
            })
          }
        }
      }
    case AT.SELECT_NORMAL:
      if (_.isArray(action.normal.tags) && action.normal.tags.length == 0) {
        return merge(state, {
          normals: [action.normal].concat(state.normals)
        })
      } else {
        return merge(state, {
          normals: [...state.normals, action.normal]
        })
      }
    case AT.UNSELECT_NORMAL:
      return merge(state, {
        normals: state.normals.filter((t, idx) => {
          return idx != action.idx
        })
      })
    case AT.COUNT_TAGS_OF_FILM:
      return merge(state, {
        normals: state.normals.map((t) => {
          if (t.tags.length == action.tags.length && _.intersection(action.tags, t.tags.map((tt) => tt.id)).length == t.tags.length) {
            return merge(t, {
              count: action.count + '部'
            })
          }
          return t
        })
      })
    default:
      return state
  }
}

function merge(state, new_part) {
  return Object.assign({}, state, new_part)
}

function newCrowd(film) {
  if (film.filter((t) => t.active).length == 1) {
    return ['总体', '25四分', '城市线级']
  }
  return ['总体']
}

function newIndicator(indi, film) {
  if (film.filter((t) => t.active).length > 1) {
    const indi_titles = indi.map((t) => t.title);
    if (_.intersection(indi_titles, ['渠道', '物料', '动机', '阻碍', '首选分流']).length > 0) {
      return default_indi;
    }
  }
  return indi
}

const rootReducer = combineReducers({
  platform
})

export default rootReducer