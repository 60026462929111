import React, {Component} from 'react';
import { Icon } from 'antd'
import _ from 'lodash'

class FilterIndi extends Component {
    constructor(props){
    super(props)
    this.state = {
      expandOne: null
    }
  }

  expandPart(){
    const { expandOne } = this.state
    const { selected,disabled,select,disabled_indi } = this.props
    
    if (expandOne != null) {
      let children = expandOne.includes
      let offsetX = 80
      switch (children.length) {
        case 3:
          offsetX = 230
          break;
        case 4:
          offsetX = 290
          break;
        case 13:
          offsetX = 45;
          break;
        default:
          break;
      }

      return (
        <div className='expand-list' style={{'left':offsetX+'px','top':'4px'}}>
          {children.map((t,idx)=>{
            const disable = disabled || disabled_indi.includes(t.title);
            let style = {'marginLeft':'4px','marginRight':'4px',opacity: disable ? 0.5 : 1}
            if (selected.includes(t.title)) {
              style = _.assign(style,{'color':'#008cd5'})
            }
            return (
              <span key={idx} className='pointer' style={style} onClick={()=> {
                if (disable) return
                select(t)
              }}>{t.title}</span>
            )
          })}
        </div>
      )
    }
    return null
  }
  render() {
    const { title,opacity,groups } = this.props
    let style = {opacity}
    const disabled = opacity!=1
    const new_groups = groups
    
    return (
      <div>
        <div className='filter-bar' style={style}>
          <p className='filter-bar-title'>{title}</p>
          <div className='filter-bar-choices'>
            {new_groups.map((group_choices,idx)=>{
              const style = (idx==(groups.length-1))?{}:{'borderRight':'1px solid #d9d9d9','marginRight':'0.4rem','paddingRight':'0.4rem'}
              return (
                <div key={idx} className='filter-bar-group' style={style}>
                  {group_choices.map((choice,index)=>{
                    if (choice==undefined) {
                      return null
                    }
                    if (choice.expand) {
                      return this.expand(choice,index,disabled);
                    } else {
                      return this.noExpand(choice,index);
                    }
                  })}
                </div>
              )
            })}
          </div>
        </div>
        {this.expandPart()}
      </div>
    )
  }
  expand(choice,index,disabled){
    const { select,selected,expandable,disabled_indi } = this.props
    const { expandOne } = this.state
    const disabled_in = disabled_indi.includes(choice.title);
    const style = disabled_in?{opacity:0.4}:{};
    let item_selected = false,intersected=[],text=choice.title
    if (expandable) {
      intersected = _.intersection(choice.includes.map((t)=> t.title),selected)
      item_selected = intersected.length > 0
    }
    const expanding = expandOne && expandOne.title==choice.title
    return (
      <div key={index} style={{...style,'flexShrink': '0'}} className={'filter-bar-btn'+(item_selected?' filter-bar-btn-selected':'')} onClick={()=> {
        if (disabled_in) return
        if (expandable) {
          this.expandFunc(disabled,choice)
          this.setState({expandOne: expanding?null:choice});
        } else {
          select(choice)
        }
      }}>
        {text}
        {expandable && (
          <span onClick={()=>{}}>
            <Icon className='' style={{'marginLeft':'4px','marginTop':'2px'}} type={expanding?'up':'down'} />
          </span>
        )}
      </div>
    )
  }
  noExpand(choice,index){
    const { select,selected,disabled_indi } = this.props
    const disabled_in = disabled_indi.includes(choice.title);
    const style = disabled_in?{opacity:0.4}:{};
    return (
      <div key={index} style={style} className={'filter-bar-btn'+(selected.includes(choice.title)?' filter-bar-btn-selected':'')} onClick={()=> {
        if (disabled_in) return
        select(choice);
        this.setState({expandOne: null});
      }}>
        {choice.title}
      </div>
    )
  }
  expandFunc(disabled, choice){
    const { expandable,select } = this.props
    const { expand } = this.state

    if (disabled) return
    if (!expandable && choice.title!='更多') {
      select(choice)
      return
    }
    if (expand == choice.title) {
      this.setState({expand:''})
    }else{
      this.setState({expand:choice.title})
    }
  }
}

export default FilterIndi;

FilterIndi.defaultProps = {
  opacity: 1,
  groups: [],
  title: '',
  selected: [],
  select: ()=>{},
  expandable: true
}
