import { TRACKING_CYCLE, TRACKING_CYCLE_OFFSET, unzipCrowd, unzipIndicator, SERVER_IP, MARKET_ALIAS } from '../config/configPro'
import _ from 'lodash'
import axios from 'axios';

const cycle_all = ['全周期'];
const cycle_42 = ['密集宣传期'];

export const calcStruct = async (options) => {
  const parsed_indi = unzipIndicator(options.indicator)
  const is_post_indi = isPostIndicator(options.indicator)

  let indi_len = parsed_indi.length
  if (is_post_indi) {
    indi_len = 1;
  }
  const is_cycle = !isDate(options.time)
  let crowd_head = getCrowdHead(options.crowd)

  const crowd_len = crowd_head.length
  const film_list = _.sortBy(options.film.filter((t) => t.active), (a) => {
    return -(new Date(a.launchday)).getTime()
  }).map((t) => {
    return { value: t.name, color: '', alias: t.id, launchday: t.launchday, id: t.id }
  })
  const film_len = film_list.length
  const indi_list = parsed_indi.map((t) => {
    return { value: t.title, color: '', alias: t.alias }
  })

  let x = '', y = '', xAxis = [], yAxis = [], normal_hidden = false

  if (film_len > 1) {
    if (is_cycle) {
      x = 'offset'
      if (cycle_all.includes(options.time.group)) {
        x = 'offset_all'
      }
      y = 'movie_id'
    } else {
      if (indi_len > 1) {
        x = 'indicator'
        y = 'movie_id'
        if (is_post_indi) {
          x = 'movie_id'
          y = 'indicator'
        }
      } else {
        if (crowd_len > 1) {
          x = 'crowd';
          y = 'movie_id';
        } else {
          if (true) {//pre release
            x = 'indicator';
            y = 'movie_id';
            if (is_post_indi) {
              x = 'movie_id';
              y = 'indicator';
            }
          }
        }
      }
    }
  } else {
    if (is_cycle) {
      x = 'offset';
      if (cycle_all.includes(options.time.group)) {
        x = 'offset_all';
      }
      if (indi_len == 1) {
        y = 'crowd';
        if (is_post_indi) {
          x = 'crowd';
          y = 'indicator';
        }
      } else {
        y = 'indicator';
      }
    } else {
      if (indi_len == 1) {
        x = 'crowd';
        y = 'indicator';
      } else {
        x = 'crowd';
        y = 'indicator';
        normal_hidden = true;
      }
    }
  }

  function axisTitle(axis) {
    switch (axis) {
      case 'offset_all':
      case 'offset':
        return TRACKING_CYCLE.map((t, idx) => {
          return { value: t, color: '', alias: TRACKING_CYCLE_OFFSET[idx] }
        });
      case 'movie_id':
        return film_list;
      case 'crowd':
        return crowd_head;
      case 'indicator':
        return indi_list;
      default:
        return []
    }
  }
  function strToObj(axis) {
    return axis.map((t) => {
      if (typeof t == 'object') {
        return t
      } else {
        return { value: t, color: '' }
      }
    })
  }
  xAxis = axisTitle(x)
  yAxis = axisTitle(y)
  xAxis = strToObj(xAxis)
  yAxis = strToObj(yAxis)
  const zero_zone = zeroZone(x, y)
  let title = ''
  if (film_len == 1) {
    title += (film_list[0].value + ' / 上映日期:' + film_list[0].launchday + ' / ' + options.indicator[0].title)
  } else {
    title += (film_list[0].value + ' 等' + film_len + '部电影 / ' + options.indicator[0].title)
  }
  if (!is_cycle) {
    if (isDate(options.time)) {
      title += (' / 监测日期：' + options.time.value)
    } else {
      title += (' / 监测周期：' + translateOffset(options.time.value))
    }
  }

  title += (' / 人群：' + crowd_head[0].value + (crowd_len > 1 ? '等' : ''))

  let disabled_tabs = is_cycle ? ['changeIdx', 'cycleVs'] : ['halfWeek', 'showFormat']
  if (is_post_indi) {
    disabled_tabs = ['changeIdx', 'cycleVs', 'halfWeek', 'showFormat']
  }
  let disabled_time = [], disabled_indi = []
  const res = await axios.get(`${SERVER_IP}api/with_filtered_questionary/${film_list[0].alias}/`)
  const marketlist = res.data

  if (film_len > 1) {
    disabled_indi = ['营销', '首选分流']
    if (indi_len > 1 || is_post_indi) {
      disabled_time = ['密集宣传期', '全周期']
    } else if (indi_len == 1 && !is_post_indi) {

    } else {
      disabled_time = ['选择监测日期', '距离上映']
    }
  } else {
    disabled_indi = excludeMarketChild(marketlist);
  }
  if (['常用热度指标', '转化率分析'].includes(options.indicator[0].title) || is_post_indi) {
    disabled_time = disabled_time.concat(['密集宣传期', '全周期'])
  }
  return {
    struct: { x, y, xAxis, yAxis, title, disabled_tabs, disabled_time, disabled_indi, zero_zone, normal_hidden },
    marketlist
  }
}

export const calcMartketStruct = async (options) => {
  //电影一部，指标一个
  const film_list = options.film.filter((t) => t.active).map((t) => {
    return { value: t.name, color: '', alias: t.id, launchday: t.launchday, id: t.id }
  })
  let crowd_head = getCrowdHead(options.crowd)
  const crowd_len = crowd_head.length
  const x = 'crowd', y = 'marketing', xAxis = crowd_head, yAxis = []
  const title = (film_list[0].value + ' / 上映日期:' + film_list[0].launchday + ' / ' + options.indicator[0].title) + (' / 人群：' + crowd_head[0].value + (crowd_len > 1 ? '等' : ''))
  const disabled_tabs = ['changeIdx', 'cycleVs', 'halfWeek', 'showFormat', 'baseShow']
  const disabled_time = ['密集宣传期', '全周期']
  const zero_zone = zeroZone(x, y)
  let disabled_indi = []

  const res = await axios.get(`${SERVER_IP}api/with_filtered_questionary/${film_list[0].alias}/`)
  const marketlist = res.data

  if (marketlist.length === 0) {
    disabled_indi = ['营销']
  }
  return {
    struct: { x, y, xAxis, yAxis, title, disabled_tabs, disabled_time, disabled_indi, zero_zone },
    marketlist
  }
}

export function generateMarketMatrix(points, normal_points, state) {
  const xAxis = state.struct.xAxis;
  const title_group = _.groupBy(points, 'title');

  let matrix = [], range_offset = 0;
  if (points.length > 0) {
    const the_offset = points[0].offset;
    if (the_offset <= -4) {
      range_offset = -4;
    } else if (the_offset > -2) {
      range_offset = 0;
    } else {
      range_offset = -2;
    }
  }
  let the_other_row = null, bases = [], base_fill = false;
  const indi = state.indicator[0].alias, indi_title = state.indicator[0].title;
  for (var key in title_group) {
    if (title_group.hasOwnProperty(key)) {
      let one = [{ value: key, color: '' }];
      xAxis.map((t) => {
        const filtered = title_group[key].filter((tg) => tg.crowd == t.alias);
        if (filtered.length == 1) {
          const fd = filtered[0].data;
          one.push({ value: fd.point, color: t.color, base: fd.base });
          if (!base_fill) {
            bases.push({ value: fd.base + (fd.base < 50 ? '*' : ''), color: t.color });
          }
        } else {
          one.push({ value: null, color: t.color });
          if (!base_fill) {
            bases.push({ value: null, color: t.color });
          }
        }
      });
      base_fill = true;
      if (key.includes('其他')) {
        the_other_row = one;
      } else {
        matrix.push(one);
      }
    }
  }
  const head_row = [{ value: '', color: '' }, ...xAxis, { value: `均值(${range_offset})`, color: 'normals' }]
  if (the_other_row) {
    matrix = _.sortBy(matrix, (row) => {
      if (row.length > 2) {
        return 0 - (_.isNumber(row[1].value) ? row[1].value : 0);
      }
      return 0;
    });
    matrix.push(the_other_row);
  }
  const nth = ['', '第一', '第二', '第三']
  const normal_col = _.range(1, matrix.length + 1).map((t) => {
    const the_indi = indi + '_' + t;
    const filtered = normal_points.filter((np) => np.indicator == the_indi && np.offset == range_offset);
    if (filtered.length === 1) {
      const fd = filtered[0].data;
      return { value: fd.point + ` (${nth[t]}${indi_title})`, color: 'normals', base: fd.base }
    } else {
      return { value: null, color: 'normals' }
    }
  })
  const base_row = [{ value: 'base', color: '' }].concat([...bases, { value: '', color: 'normals' }]);
  matrix = [head_row, base_row].concat(matrix.map((row, idx) => {
    return row.concat([normal_col[idx]])
  }));
  return { matrix }
}

export function generateDiversionMatrix(points, normal_points, state) {
  const xAxis = state.struct.xAxis;
  const title_group = _.groupBy(points, 'title');
  let matrix = [], the_movie_row = null;
  const the_movie = state.film.filter((t) => t.active)[0]
  let offset = null;
  for (var key in title_group) {
    if (title_group.hasOwnProperty(key)) {
      let one = [{ value: key, color: '' }];
      xAxis.map((t) => {
        const filtered = title_group[key].filter((tg) => tg.crowd == t.alias);
        if (filtered.length == 1) {
          const fd = filtered[0].data
          one.push({ value: fd.point, color: t.color, base: fd.base, offset: fd.offset });
          if (!offset && the_movie.name == key) {
            offset = filtered[0].offset;
          }
        } else {
          one.push({ value: null, color: t.color, base: null });
        }
      })

      if (the_movie.name == key) {
        the_movie_row = [].concat(one);
      } else {
        matrix.push(one);
      }
    }
  }
  const empty_row = [{ value: '', color: '' }].concat(xAxis.map(() => {
    return { value: null, color: '' };
  }))
  const head = [{ value: '', color: '' }, ...xAxis]
  if (the_movie_row) {
    const base_row = [{ value: 'base', color: '' }].concat(the_movie_row.filter((t, idx) => idx !== 0).map((t) => {
      return { value: t.base + (t.base < 50 ? '*' : ''), color: t.color }
    }))
    const normals = normal_points.filter((t) => parseFloat(t.offset) == offset)
    const normal_rows = normals.map((n) => {

      return [{ value: translateNormalTag(n.tag_ids, state.tagList), color: 'normals' }].concat(xAxis.map((t, idx) => {
        return { value: idx === 0 ? n.data.point : null, color: t.color };
      }))
    })

    const tail = _.sortBy(matrix, (row) => {
      if (row.length > 2) {
        return 0 - (_.isNumber(row[1].value) ? row[1].value : 0);
      }
      return 0;
    });
    return { matrix: [head, base_row, ...normal_rows, the_movie_row, empty_row].concat(tail) }
  } else {
    return { matrix: [head].concat(matrix) }
  }
}

function getCrowdHead(crowds) {
  let crowd_head = []
  crowds.map((t, idx) => {
    const unzipped = unzipCrowd(t)
    const tmp = unzipped.children_cn.map((t, index) => {
      return {
        value: t,
        color: idx % 2 == 0 ? 'odd-row' : 'even-row',
        alias: unzipped.children_en[index]
      }
    })
    crowd_head = crowd_head.concat(tmp)
  })
  return crowd_head
}

function offsetAll(struct, points) {
  if (struct.x == 'offset_all') {
    const xAxis = _.sortBy(_.uniqBy(points, 'offset'), (t) => parseFloat(t.offset)).map((t) => {
      return { value: translateOffset(t.offset), color: '', alias: t.offset }
    })
    return Object.assign({}, struct, {
      xAxis,
      x: 'offset'
    })
  }
  return struct
}

export function generateMatrix(points, normal_points, state) {
  const struct = offsetAll(state.struct, points)
  let matrix = []
  const head = ([{ value: struct.zero_zone, color: '', alias: '' }].concat(struct.xAxis))

  matrix.push(head)
  struct.yAxis.map((y) => {
    let row_points = []
    //找到数值
    struct.xAxis.map((x) => {
      row_points.push(targetPoint(points, x.alias, y.alias, struct.x, struct.y))
    })
    let color = ''
    if (struct.y == 'crowd') {
      color = y.color
    }
    //覆盖颜色
    row_points = row_points.map((t, idx) => {
      let tmp_color = color == '' ? struct.xAxis[idx].color : color
      return _.assign(t, { color: tmp_color })
    })
    //填充长度
    if (row_points.length == 0) {
      row_points = _.fill(Array(head.length - 1), { value: '', color: color })
    }
    //加进matrix
    matrix.push([y].concat(row_points))
  })

  let normal_column = []

  if (normal_points.length > 0 && points.length > 0 && !struct.normal_hidden) {
    const offset = points[0].offset
    const normal_group = _.groupBy(normal_points, 'tag_ids')
    const tags_seed = _.keys(normal_group)

    if ((struct.x == 'crowd' && struct.y == 'indicator') || (struct.x == 'movie_id' && struct.y == 'indicator')) {
      _.sortBy(tags_seed, (ts) => ts.length).map((t) => {
        let tmp = [{ value: translateNormalTag(normal_group[t][0].tag_ids, state.tagList), color: 'normals' }]

        struct.yAxis.map((y) => {
          tmp.push(targetPoint(normal_group[t], offset, y.alias, 'offset', struct.y))
        })
        normal_column.push(tmp)
      })
    }
    matrix = matrix.map((t, idx) => {
      let nor_row = normal_column.map((nc) => nc[idx])
      return t.concat(nor_row)
    })
  }
  if ((struct.x == 'indicator' || struct.x == 'crowd') && struct.y == 'movie_id') {
    const film_seed = _.groupBy(_.uniqBy(points, 'movie_id'), (t) => t.offset)
    let offset_seed = _.keys(film_seed).sort((a, b) => parseFloat(a) > parseFloat(b))

    const normal_group = _.groupBy(normal_points, 'tag_ids')
    const tags_seed = _.keys(normal_group)
    let tmp_matrix = [matrix[0]]
    let tmp_valued = []
    if (offset_seed.length == 0 && state.time.group == '距离上映') {
      offset_seed = [state.time.value]
    }
    offset_seed.map((ofst) => {
      const same_cycle = film_seed[ofst] == undefined ? [] : film_seed[ofst].map((fs) => fs.movie_id)

      //相同周期的均值组
      const ofst_normal = tags_seed.map((t) => {
        let nor_point = [{ value: translateNormalTag(normal_group[t][0].tag_ids, state.tagList), color: 'normals', offset: ofst }]
        return nor_point.concat(struct.xAxis.map((sx) => {
          return targetNormalPoint(normal_points, ofst, struct.x, sx.alias, normal_group[t][0].tag_ids);
        }))
      })
      ofst_normal.map((one_normal) => {
        tmp_matrix.push(one_normal)
      })

      same_cycle.map((mid) => {
        const idx = _.findIndex(struct.yAxis, (t) => t.id == mid)
        if (idx != -1) {
          tmp_valued.push(idx)
          tmp_matrix.push(matrix[idx + 1])
        }
      })
    })
    const without_data = matrix.filter((t, idx) => !tmp_valued.includes(idx - 1) && idx != 0)
    if (without_data.length > 0) {
      const len = struct.xAxis.length
      matrix = tmp_matrix.concat([fillRow('', len)]).concat(without_data)
    } else {
      matrix = tmp_matrix
    }
  }
  if (struct.x == 'offset' && struct.y == 'crowd') {
    matrix = matrix.concat(arrangeNormalRowBy(normal_points, Object.assign({}, state, { struct }), 'offset'))
  }
  if (struct.x == 'offset' && struct.y == 'movie_id') {
    matrix = matrix.concat(arrangeNormalRowBy(normal_points, Object.assign({}, state, { struct }), 'offset'))
  }

  matrix = _.without(matrix.map((t) => {
    return _.without(t, undefined)
  }), undefined)
  return { matrix }
}

function arrangeNormalRowBy(normal_points, state, by) {
  const struct = state.struct
  const normal_group = _.groupBy(normal_points, 'tag_ids')
  const tags_seed = _.keys(normal_group)
  let nor_row = []
  tags_seed.map((t) => {
    let nor_point = [{ value: translateNormalTag(normal_group[t][0].tag_ids, state.tagList), color: 'normals' }]
    struct.xAxis.map((sx) => {
      const filtered = normal_group[t].filter((o) => o[by] == sx.alias)
      if (filtered.length == 1) {
        nor_point.push({
          value: filtered[0].data.point,
          base: filtered[0].data.base,
          color: 'normals'
        })
      } else {
        nor_point.push({
          value: null,
          base: null,
          color: 'normals'
        })
      }
    })
    nor_row.push(nor_point)
  })
  return nor_row
}

function fillRow(title, len) {
  return [{ value: title, color: 'normals', no_data: true }].concat(_.fill(Array(len), { value: '', color: 'normals' }))
}
function targetPoint(source_points, x, y, xkey, ykey) {
  const filtered = source_points.filter((t) => t[xkey] == x && t[ykey] == y)
  if (filtered.length == 1) {
    return {
      value: filtered[0].data.point,
      ctime: filtered[0].data.change_from_previous,
      cweek: filtered[0].data.change_from_previous_week,
      base: filtered[0].data.base,
      color: 'normals'
    }
  } else {
    return {
      value: null,
      ctime: null,
      cweek: null,
      base: null,
      color: 'normals'
    }
  }
}

function targetNormalPoint(source, offset, key, value, tag_ids) {
  const filtered = source.filter((t) => {
    return t.offset == offset && t[key] == value && _.difference(tag_ids, t.tag_ids).length == 0 && tag_ids.length == t.tag_ids.length
  })
  if (filtered.length == 1) {
    return {
      value: filtered[0].data.point,
      color: 'normals',
      offset
    }
  } else {
    return {
      value: null,
      color: 'normals',
      offset
    }
  }
}

export function translateNormal(normal) {
  if (normal.tags.length == 0) {
    return '总体'
  } else {
    return normal.tags.map((t) => t.name).join('+') + '(' + normal.count + ')'
  }
}

export function isPostIndicator(indis) {
  if (indis.length == 1 && ['喜爱度', '推荐度', '预期达成'].includes(indis[0].title)) {
    return true
  }
  return false
}

export function translate(platform) {
  const film = platform.film.filter((t) => t.active).map((t) => t.id)
  const indi = unzipIndicator(platform.indicator)
  const indicators = indi.map((t) => t.alias)

  let crowds = []
  platform.crowd.map((t) => {
    crowds = crowds.concat(unzipCrowd(t).children_en)
  })

  let time = platform.time, tmp_time = null

  let is_offset = true
  if (cycle_42.includes(time.group)) {
    tmp_time = '-4~2'
  } else if (cycle_all.includes(time.group)) {
    tmp_time = 'all'
  } else if (time.group === '选择监测日期') {
    is_offset = false
  } else {
    tmp_time = time.value
  }
  const tag_ids = platform.normals.map((t) => {
    if (t.tags.length == 0) {
      return []
    } else {
      return t.tags.map((tt) => tt.id)
    }
  })
  const without_date = {
    movie_ids: film,
    indicators,
    crowds,
    tag_ids: JSON.stringify(tag_ids)
  }

  if (is_offset) {
    return _.assign(without_date, { offset: tmp_time });
  } else {
    return _.assign(without_date, { date: time.value });
  }
}

export function isDate(time) {
  return !(['密集宣传期', '全周期'].includes(time.group));
}

function translateNormalTag(tags, tagList) {
  if (tags.length == 0) {
    return '总体均值'
  } else {
    let str = ''
    tags.map((t, idx) => {
      const filtered = tagList.filter((tl) => tl.id == t)
      if (filtered.length == 1) {
        str += (filtered[0].name + ((idx == (tags.length - 1)) ? '' : '+'))
      }
    })
    return str + '均值'
  }
}

export function translateOffset(v) {
  const idx = TRACKING_CYCLE_OFFSET.indexOf(v + '')
  if (TRACKING_CYCLE[idx] != undefined) {
    return TRACKING_CYCLE[idx]
  } else {
    if (v == undefined) {
      return '超出范围'
    } else {
      const offset = new Number(v)
      let str = ''
      if (offset > 0) {
        str = '映后'
        if (Math.floor(offset) == offset) {
          str += (parseInt(offset) + '周')
        } else {
          str += (Math.floor(parseInt(offset)) + '.5周')
        }
      } else if (offset < 0) {
        str = '映前'
        if (Math.floor(offset) == offset) {
          str += (parseInt(-offset) + '周')
        } else {
          str += (Math.floor(parseInt(-offset)) + '.5周')
        }
      } else {
        str = '上映当周'
      }
      return str
    }
  }
}

function zeroZone(x, y) {
  function title(axis) {
    switch (axis) {
      case 'crowd': return '人群'
      case 'indicator': return '指标'
      case 'movie_id': return '电影'
      case 'offset':
      case 'offset_all':
        return '日期'
      case 'marketing':
        return '营销'
      default: break;
    }
  }
  return title(y) + '/' + title(x)
}

export function isMarketing(indi) {
  return ['渠道', '物料', '动机', '阻碍'].includes(indi)
}

export function excludeMarketChild(marketlist) {
  const cate_in = _.uniq(marketlist.map((t) => t.category)).map((t) => {
    return MARKET_ALIAS[t];
  })
  return _.xor(['渠道', '物料', '动机', '阻碍'], cate_in);
}
