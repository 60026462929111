import React, {Component} from 'react';
import { Icon } from 'antd'
const unchecked = require('../../images/unchecked.png');
const checked = require('../../images/checked.png');

class FilterFilm extends Component {
  render() {
    const { title,opacity,groups,cb,select,disableIt,singleSelect } = this.props
    let extra = null, style = {opacity}
    const disabled = opacity!=1
    extra = (
      <div className='add-movie' onClick={()=>{ cb();}}>
        <Icon className='' style={{'marginRight':'4px'}} type={'plus'} />
        <span>添加电影</span>
      </div>
    )
    return (
      <div>
        <div className='filter-bar' style={style}>
          <p className='filter-bar-title'>{title}</p>
          <div className='filter-bar-choices'>
            {groups.map((group_choices,idx)=>{
              const style = (idx==(groups.length-1))?{}:{'borderRight':'1px solid #d9d9d9','marginRight':'0.4rem','paddingRight':'0.4rem'}
              return (
                <div key={idx} className='filter-bar-group' style={style}>
                  {group_choices.map((choice,index)=>{
                    if (choice==undefined) {
                      return null
                    }
                    switch (choice.type) {
                      case 'checkbox':
                        return (
                          <div key={index} className={'filter-bar-select'+(choice.active?' filter-bar-select-selected':'')} style={{'marginTop':'2px','marginBottom':'2px'}}>
                            <div onClick={()=>{disableIt(choice.id,!choice.active)}}>
                              {choice.active?(<img src={checked} width='15'/>):(<img src={unchecked} width='15'/>)}
                            </div>
                            <div onClick={()=> singleSelect(choice.id)}>
                              <p>{choice.name}</p>
                              <p>{choice.launchday}</p>  
                            </div>
                            <div style={{'marginRight':'0','paddingRight':'0','justifyContent':'flex-start'}} onClick={()=>{
                              if (disabled) return
                              select(choice.id)
                            }}>
                              <Icon className='' style={{'marginLeft':'4px','fontSize':'1rem','marginRight':'0'}} type={'close'} />
                            </div>
                          </div>
                        )
                      default:
                        return null
                    }
                  })}
                  {extra}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default FilterFilm;