import React, { Component } from 'react';

require('../styles/FilmIndex.scss')
import _ from 'lodash'
import { Icon } from 'antd'

class FilmIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tags: [],
      searchValue: '',
      hovering: -1
    }
  }
  render() {
    const { defaultTags, platform, close } = this.props
    const { tags, searchValue, hovering } = this.state

    const films = platform.film
    let films_in_survey = []

    const left = platform.filmList.map((t) => {
      const tmp = t.list.filter((ff) => {
        //过滤时间
        if ((films_in_survey.length != 0 && films_in_survey.includes(ff.id)) || films_in_survey.length == 0) {
          //过滤标签
          if (tags.length == 0 || ((tags.length > 0 && _.intersection(tags, ff.tags).length >= tags.length))) {
            //过滤搜索（名称搜索和曾用名搜索）
            if (searchValue.length == 0 || (searchValue.length > 0 && (ff.title.indexOf(searchValue) >= 0 || ff.alias.some(item => {
              return item.indexOf(searchValue) >= 0
            })))) {
              return ff
            }
          }
        }
      })

      return Object.assign({}, t, {
        list: tmp
      })
    }).filter((t) => t.list.length > 0)

    return (
      <div className='film-index'>
        <div style={{ 'height': '1.8rem' }}>
          <Icon className='pull-right' type={'close'} onClick={() => {
            close();
          }} />
        </div>
        <div>
          <input value={searchValue} type={'text'} className='film-searcher' onChange={(e) => this.setState({ searchValue: e.target.value })} />
        </div>
        <div className='tag-filter text-left'>
          <span></span>
          {defaultTags.map((t, idx) => {
            const inn = tags.includes(t)
            const cl = inn ? 'actived' : ''
            return (
              <span key={idx} className={cl} onClick={() => {
                this.toggleTag(t, inn)
              }}>{t}</span>
            )
          })}
        </div>
        {(<div className='btn btn-primary btn-clear-select' onClick={() => {
          let all = []
          if (films.length > 0) {

          } else {
            left.map((t) => {
              all = all.concat(t.list.map((ff) => {
                return { name: ff.title, id: ff.id, launchday: ff.launchday, active: true }
              }))
            })
          }
          this.props.cb(all)
        }}>
          {films.length > 0 ? '清除' : '全选'}
        </div>)}
        <div className='films-to-choose'>
          {left.map((t, idx) => {
            const con = (
              t.list.map((ff, index) => {
                //过滤时间
                const cl = films.includes(ff.title) ? 'actived' : ''
                if (searchValue.length == 0 || (searchValue.length > 0 && (ff.title.includes(searchValue) || ff.alias[ff.alias.length - 1].includes(searchValue)))) {
                  return (
                    <span key={index} className={cl} onClick={() => {
                      if (cl != 'actived' && films.filter((ft) => ft.id == ff.id).length == 0) {
                        this.props.cb([...films, { name: ff.title, id: ff.id, launchday: ff.launchday, active: true }])
                      }
                    }}>
                      {/* 搜索结果展示曾用名 */}
                      {ff.title}
                    </span>
                  )
                }
              })
            )
            return (
              <div key={idx} className='week-block' onMouseEnter={() => {
                this.setState({ hovering: idx });
              }} onMouseLeave={() => {
                this.setState({ hovering: -1 });
              }}>
                <div className='week-block-head'>
                  <span className='text-center week-date'>{this.getWeek(t.date)}</span>
                  {hovering == idx && (
                    <span className='btn-border week-select' onClick={() => {
                      let all = []
                      left.map((wt, index) => {
                        if (index == hovering) {
                          all = films.concat(wt.list.filter((wtf) => {
                            return films.filter((ft) => ft.id == wtf.id).length == 0
                          }).map((wtf) => {
                            return { name: wtf.title, id: wtf.id, launchday: wtf.launchday, active: true }
                          }))
                        }
                      })
                      this.props.cb(all)
                    }}>周选</span>
                  )}
                </div>
                <div className='week-film'>
                  {_.without(con)}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
  getWeek(date) {
    const { platform } = this.props
    let index = -1
    platform.filmList.map((t, idx) => {
      if (t.date == date) {
        index = platform.filmList.length - idx
      }
    })
    const to = new Date(date)
    to.setDate(to.getDate() + 6)
    return date.replace(/-/g, '/') + ' — ' + to.toLocaleDateString() + ' （第' + index + '期）'
  }
  //toggle to select or unselect
  toggleTag(t, inn) {
    const { tags } = this.state
    let tmp = []
    if (inn) {
      tmp = _.without(tags, t)
    } else {
      tmp = [...tags, t]
    }
    this.setState({ tags: tmp })
  }
}

FilmIndex.defaultProps = {
  defaultTags: ['中国', '美国', '动画', '续集', '改编', '2014', '2015', '2016']
};

export default FilmIndex;
