require('normalize.css/normalize.css');
import '../styles/App.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchNormalTags, fetchFilmList, fetchSurveyList, getUserInfo } from '../actions';
import React from 'react';
import Chart from './Chart';
import FilterOption from './FilterOption'
import { getCookie } from '../util/cookie'

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.className = 'Main'
    this.state = {}
  }
  componentDidMount() {
    const { fetchFilmList, fetchNormalTags, fetchSurveyList, getUserInfo } = this.props

    //验证登录合法性
    getUserInfo()


    fetchFilmList()
    fetchNormalTags()
    fetchSurveyList()
  }
  render() {
    const { platform } = this.props
    const { login } = platform

    let matrix = platform.matrix

    if (platform.time.group == '选择监测日期' && platform.film.filter((t) => t.active).length > 1) {
      matrix = matrix.filter((t) => {
        return t[0].color != 'normals'
      })
    }


    return (
      <div className='container' >
        <FilterOption />
        <Chart
          matrix={matrix}
          changeIdx={platform.changeIdx}
          cycleVs={platform.cycleVs}
          baseShow={platform.baseShow}
          showFormat={platform.showFormat}
          chartShow={platform.generated}
          halfWeek={platform.halfWeek}
          time={platform.time}
          loading={platform.loading}
          struct={platform.struct}
        />
      </div>
    );
  }

  getChangeIdx(tabs, title) {
    const indis = tabs.filter((t) => t.title == title)
    if (indis.length == 1) {
      switch (title) {
        case '电影':
          return indis[0].radio[indis[0].selected].cn
        case '半周数据':
          return indis[0].radio[indis[0].selected].cn == '显示'
        case '数值/变化值':
          return indis[0].selected
        default:
          return indis[0].radio[indis[0].selected].en
      }
    }
    return null
  }
}

Main.defaultProps = {

};

function mapStateToProps(state) {
  return {
    platform: state.platform
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNormalTags: bindActionCreators(fetchNormalTags, dispatch),
    fetchFilmList: bindActionCreators(fetchFilmList, dispatch),
    fetchSurveyList: bindActionCreators(fetchSurveyList, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),

  }
}
let obj = connect(
  mapStateToProps,
  mapDispatchToProps
)(Main)

export default obj
