import React, { Component, PropTypes } from 'react';
// import PropTypes from 'prop-types';
import { Icon } from 'antd'
import _ from 'lodash'

const CROWD_OFFSET = [{
  cn: '25四分',
  x: 30
}, {
  cn: '城市线级',
  x: 150
}, {
  cn: '男女',
  x: 240
}, {
  cn: '25士',
  x: 280
}, {
  cn: '30士',
  x: 340
}, {
  cn: '年龄层',
  x: 360
}, {
  cn: '30四分',
  x: 410
}, {
  cn: '收入',
  x: 500
}, {
  cn: '是否学生',
  x: 590
}, {
  cn: '是否家长',
  x: 660
}]

export default class FilterCrowd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandOne: null
    }
  }

  expandPart() {
    const { expandOne } = this.state
    const { selected, disabled, select, expandable } = this.props
    if (!expandable) {
      return null
    }
    if (expandOne != null) {
      let children = expandOne.children
      let offsetX = 200
      const filtered = CROWD_OFFSET.filter((t) => t.cn == expandOne.cn)
      if (filtered.length == 1) {
        offsetX = filtered[0].x
      }
      return (
        <div className='expand-list' style={{ 'left': offsetX + 'px', 'top': '4px' }}>
          {children.map((t) => {
            let style = { 'marginLeft': '4px', 'marginRight': '4px' }
            if (selected.includes(t.cn)) {
              style = _.assign(style, { 'color': '#008cd5' })
            }
            return (
              <span key={t.cn} className='pointer' style={style} onClick={() => {
                if (disabled) return
                select(t.cn)
              }}>{t.cn}</span>
            )
          })}
        </div>
      )
    }
    return null
  }
  parseGroups(crowdMoreExpanded, groups) {
    if (crowdMoreExpanded) {
      const without_tail = _.slice(groups, 0, groups.length - 1)
      const flatten = _.last(groups).children.map((t) => {
        return _.assign(t, { type: 'expand' })
      })
      return without_tail.concat(flatten)
    }
    return groups
  }
  render() {
    const { title, opacity, groups, select, selected, expandable, expandCrowd, crowdMoreExpanded } = this.props
    const { expandOne } = this.state
    let style = { opacity }
    const disabled = opacity != 1

    const new_groups = this.parseGroups(crowdMoreExpanded, groups)

    return (
      <div>
        <div className='filter-bar' style={style}>
          <p className='filter-bar-title'>{title}</p>
          <div className='filter-bar-choices'>
            {new_groups.map((choice, idx) => {
              const style = {}
              if (choice == undefined) {
                return null
              }
              switch (choice.type) {
                case 'expand':
                  let item_selected = false, intersected = [], text = choice.cn
                  if (expandable) {
                    intersected = _.intersection(choice.children_cn, selected)
                    item_selected = intersected.length > 0
                  } else {
                    item_selected = selected.includes(choice.cn)
                  }
                  const expanding = expandOne && expandOne.cn == choice.cn
                  return (
                    <div key={idx} className='filter-bar-group' style={style}>
                      <div style={{ 'flexShrink': '0' }} className={'filter-bar-btn' + (item_selected ? ' filter-bar-btn-selected' : '')} onClickCapture={() => {
                        if (choice.cn == '更多') {
                          expandCrowd()
                        } else {
                          if (expandable) {
                            this.expandFunc(disabled, choice)
                            this.setState({ expandOne: expanding ? null : choice });
                          } else {
                            select(choice.cn)
                          }
                        }
                      }}>
                        {text}
                        {expandable && (
                          <span onClick={() => { }}>
                            <Icon className='' style={{ 'marginLeft': '4px', 'marginTop': '2px' }} type={expanding ? 'up' : 'down'} onClickCapture={() => { }} />
                          </span>
                        )}
                      </div>
                    </div>
                  )
                default:
                  return (
                    <div key={idx} className='filter-bar-group' style={style}>
                      <div className={'filter-bar-btn' + (selected.includes(choice.cn) ? ' filter-bar-btn-selected' : '')} onClick={() => {
                        if (disabled) return
                        select(choice.cn)
                      }}>
                        {choice.cn}
                      </div>
                    </div>
                  )
              }
            })}
          </div>
        </div>
        {this.expandPart()}
      </div>
    )
  }

  expandFunc(disabled, choice) {
    const { expandable, select } = this.props
    const { expand } = this.state

    if (disabled) return
    if (choice.type == 'expand') {
      return
    }
    if (!expandable && choice.cn != '更多') {
      select(choice.cn)
      return
    }
    if (expand == choice.cn) {
      this.setState({ expand: '' })
    } else {
      this.setState({ expand: choice.cn })
    }
  }
}

FilterCrowd.defaultProps = {
  opacity: 1,
  groups: [],
  title: '',
  selected: [],
  select: () => { },
  expandable: true
}

FilterCrowd.propTypes = {
  opacity: PropTypes.number,
  title: PropTypes.string,
  choices: PropTypes.array
};
