import _ from 'lodash'

export const TRACKING_CYCLE = ['映前4周', '映前3.5周', '映前3周', '映前2.5周', '映前2周', '映前1.5周', '映前1周', '映前0.5周', '上映当周', '上映周末', '映后1周', '上映1.5周', '映后2周']

export const TRACKING_CYCLE_OFFSET = ['-4.0', '-3.4', '-3.0', '-2.4', '-2.0', '-1.4', '-1.0', '-0.4', '0.0', '0.6', '1.0', '1.6', '2.0']

export const INDICATOR_GROUPS = [
  [{
    title: '常用热度指标',
    expand: false,
    includes: [
      { title: '认知', alias: 'awareness' },
      { title: '兴趣', alias: 'interest' },
      { title: '首选', alias: 'primary' },
      { title: '次选', alias: 'secondary' },
      { title: '第一提及', alias: 'tom' },
      { title: '主动搜索', alias: 'search' }
    ]
  }, {
    title: '转化率分析',
    expand: false,
    includes: [
      { title: '认知', alias: 'awareness' },
      { title: '兴趣Top2(兴趣转化率)', alias: 'interest_top2' },
      { title: '兴趣Top2 rebase', alias: 'interest_top2_rebase' },
      { title: '首选转化率', alias: 'interest_primary_convert' },
      { title: '首选rebase', alias: 'primary_rebase' }
    ]
  }], [{
    title: '热度',
    expand: true,
    includes: [
      { title: '认知', alias: 'awareness' },
      { title: '兴趣', alias: 'interest' },
      { title: '首选', alias: 'primary' },
      { title: '次选', alias: 'secondary' },
      { title: '第一提及', alias: 'tom' },
      { title: '主动搜索', alias: 'search' },
      { title: '兴趣Top2', alias: 'interest_top2' },
      { title: '看过', alias: 'watched' },
      { title: '兴趣rebase', alias: 'interest_rebase' },
      { title: '兴趣Top2 rebase', alias: 'interest_top2_rebase' },
      { title: '首选rebase', alias: 'primary_rebase' },
      { title: '首选转化率', alias: 'interest_primary_convert' },
      { title: '本周首选', alias: 'primary_cw' },
      { title: '本周肯定看', alias: 'primary_opening_and_release' }
    ]
  }, {
    title: '映后',
    expand: true,
    type: 'expand',
    includes: [
      {
        title: '喜爱度', alias: 'ratings', options: [
          { title: '非常棒', alias: 'ratings_p1' },
          { title: '很好', alias: 'ratings_p2' },
          { title: '还不错', alias: 'ratings_p3' },
          { title: '一般', alias: 'ratings_p4' },
          { title: '不太好', alias: 'ratings_p5' }
        ]
      },
      {
        title: '推荐度', alias: 'recommendations', options: [
          { title: '肯定会', alias: 'recommendations_p1' },
          { title: '可能会', alias: 'recommendations_p2' },
          { title: '说不准', alias: 'recommendations_p3' },
          { title: '可能不会', alias: 'recommendations_p4' },
          { title: '肯定不会', alias: 'recommendations_p5' }
        ]
      },
      {
        title: '预期达成', alias: 'expectations', options: [
          { title: '超过预期', alias: 'expectations_p1' },
          { title: '符合预期', alias: 'expectations_p2' },
          { title: '没有达到预期', alias: 'expectations_p3' }
        ]
      }
    ]
  }, {
    title: '营销',
    expand: true,
    includes: [
      { title: '渠道', alias: 'channel' },
      { title: '物料', alias: 'material' },
      { title: '动机', alias: 'motivation' },
      { title: '阻碍', alias: 'barrier' }
    ]
  }, {
    title: '首选分流',
    expand: false,
    includes: [
      { title: '首选分流', alias: 'diversion' }
    ]
  }]
]

export function unzipIndicator(indis) {
  if (indis.length > 0) {
    if (_.isArray(indis[0].includes)) {
      return indis[0].includes;
    }
    if (_.isArray(indis[0].options)) {
      return indis[0].options;
    }
  }
  return indis;
}

export const DATE_GROUPS = [
  [{
    cn: '密集宣传期',
    en: '-4+2'
  }, {
    cn: '全周期',
    en: 'all'
  }, {
    cn: '选择监测日期',
    type: 'select',
    options: []
  }, {
    cn: '距离上映',
    type: 'select',
    options: TRACKING_CYCLE
  }]
]

export const CROWD_GROUPS = [{
  cn: '总体',
  en: 'overall',
  children: [{ cn: '总体', en: 'overall' }]
}, {
  cn: '25四分',
  en: 'gender_age25',
  type: 'expand',
  children: [{ cn: '<25男', en: 'male_below25' }, { cn: '≥25男', en: 'male_above25' }, { cn: '<25女', en: 'female_below25' }, { cn: '≥25女', en: 'female_above25' }]
}, {
  cn: '城市线级',
  en: 'tier',
  type: 'expand',
  children: [{ cn: '一线', en: 'tier1' }, { cn: '二线', en: 'tier2' }, { cn: '三线', en: 'tier3' }]
}, {
  cn: '更多',
  en: 'more',
  type: 'expand',
  children: [{
    cn: '男女',
    en: 'gender',
    children: [{ cn: '男', en: 'male' }, { cn: '女', en: 'female' }]
  }, {
    cn: '25士',
    en: 'age25',
    children: [{ cn: '<25', en: 'below25' }, { cn: '≥25', en: 'above25' }]
  }, {
    cn: '30士',
    en: 'age30',
    children: [{ cn: '<30', en: 'below30' }, { cn: '≥30', en: 'above30' }]
  }, {
    cn: '年龄层',
    en: 'age_layer',
    children: [{ cn: '18-24', en: 'layer1' }, { cn: '25-29', en: 'layer2' }, { cn: '30-34', en: 'layer3' }, { cn: '≥35', en: 'layer4' }]
  }, {
    cn: '30四分',
    en: 'gender_age30',
    children: [{ cn: '<30男', en: 'male_below30' }, { cn: '≥30男', en: 'male_above30' }, { cn: '<30女', en: 'female_below30' }, { cn: '≥30女', en: 'female_above30' }]
  }, {
    //   cn: '收入',
    //   en: 'income',
    //   children: [{cn:'低收入',en:''},{cn:'中收入',en:''},{cn:'高收入',en:''}]
    // },{
    cn: '是否学生',
    en: 'is_student',
    children: [{ cn: '学生', en: 'student' }, { cn: '非学生', en: 'non_student' }]
  }, {
    cn: '是否家长',
    en: 'is_parent',
    children: [{ cn: '家长', en: 'parent' }, { cn: '非家长', en: 'non_parent' }]
  }]
}]

function recurFind(crowd, seed) {
  if (_.isArray(seed)) {
    const filtered = seed.filter((t) => t.cn == crowd)
    if (filtered.length == 1) {
      return filtered[0]
    } else {
      for (var i = 0; i < seed.length; i++) {
        const one = recurFind(crowd, seed[i])
        if (one != undefined) {
          return one
        }
      }
    }
  } else {
    if (seed.cn == crowd) {
      return seed
    } else if (seed.children != undefined) {
      return recurFind(crowd, seed.children)
    }
  }
}

export function unzipCrowd(crowd) {
  let unzipped = recurFind(crowd, CROWD_GROUPS)
  return _.assign(unzipped, {
    children_cn: unzipped.children != undefined ? unzipped.children.map((t) => t.cn) : [unzipped.cn],
    children_en: unzipped.children != undefined ? unzipped.children.map((t) => t.en) : [unzipped.en]
  })
}

export const NORMAL_GROUPS = [
  [{
    cn: '总体'
  }]
]

export const VALUE_CHANGE_GROUP = [{
  cn: '数值+变化量'
}, {
  cn: '数值'
}, {
  cn: '变化量'
}]

export const CYCLE_COMPARE_GROUP = [{
  cn: '整周'
}, {
  cn: '上次'
}]

export const BASE_GROUP = [{
  cn: '显示'
}, {
  cn: '不显示'
}]

export const TABLE_FORMAT = [{
  cn: '表格'
}, {
  cn: '折线图'
}]

export const HALF_WEEK_DISPLAY = [{
  cn: '显示'
}, {
  cn: '隐藏'
}]

export const CHART_CONFIG = {
  chart: {
    type: 'line',
    height: 500
  },
  title: {  //主标题
    text: ''
  },
  xAxis: { //X轴
    lineWidth: 1,
    tickWidth: 1,
    tickLength: -10,
    tickmarkPlacement: 'on',
    reversed: false,
    tickInterval: 2,
    categories: TRACKING_CYCLE,
    title: {
      text: ''
    },
    plotLines: [{
      color: 'red',            //线的颜色，定义为红色
      dashStyle: 'dash',//标示线的样式，默认是solid（实线），这里定义为长虚线
      value: 8.5,                //定义在哪个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
      width: 2,
      id: 'plot-line',
      label: {
        text: '',     //标签的内容
        align: 'center',                //标签的水平位置，水平居左,默认是水平居中center
        y: 472,
        rotation: 0,
        style: {
          color: 'red'
        }
        // x:10                         //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
      }
    }],
    labels: {
      // x: 30,
      style: {
        color: '#999'
      }
    }
  },
  yAxis: { //Y轴
    title: {
      text: ''
    },
    plotLines: [{
      value: 0,
      width: 0,
      height: 0,
      color: '#808080'
    }],
    labels: {
      formatter: function () {
        return this.value
      },
      style: {
        color: '#999'
      }
    },
    min: 0
  },
  tooltip: {
    shared: true,
    backgroundColor: 'rgba(255,255,255,1)',
    shadow: true,
    borderColor: 'rgba(0,0,0,0.1)',
    borderWidth: 1,
    crosshairs: [{
      width: 30,
      color: 'rgba(216,216,216,0.3)'
    }]
  },
  credits: { //版权信息
    enabled: false
  },
  legend: {  //图例
    enable: false
  },
  subtitle: { //
    text: '',
    x: 40
  },
  series: [{
    name: '🌰',
    showInLegend: false,
    marker: {
      enabled: false,
      symbol: 'circle'
    },
    data: [1, 3, 4, 5, 6, 7, 8, 1, 1, 1, null, null]//
  }]
}

export const SERVER_IP = 'https://king.fanink.cn/'
export const USER_HOST = 'https://sso.fanink.cn/'

export const MARKET_ALIAS = {
  channel: '渠道',
  material: '物料',
  motivation: '动机',
  barrier: '阻碍'
}
