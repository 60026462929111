export const FETCH_FILMLIST = 'FETCH_FILMLIST'
export const FETCH_SURVEYLIST = 'FETCH_SURVEYLIST'
export const FETCH_TAGLIST = 'FETCH_TAGLIST'
export const FETCH_MARKETINGLIST = 'FETCH_MARKETINGLIST'

export const SELECT_FILM = 'SELECT_FILM'
export const DISABLE_FILM = 'DISABLE_FILM'
export const UNSELECT_FILM = 'UNSELECT_FILM'
export const SINGLE_SELECT_FILM = 'SINGLE_SELECT_FILM'
export const SELECT_TIME = 'SELECT_TIME'
export const SELECT_INDICATOR = 'SELECT_INDICATOR'
export const SELECT_CROWD = 'SELECT_CROWD'
export const SELECT_NORMAL = 'SELECT_NORMAL'
export const UNSELECT_NORMAL = 'UNSELECT_NORMAL'
export const COUNT_TAGS_OF_FILM = 'COUNT_TAGS_OF_FILM'

export const EXPAND_CROWD = 'EXPAND_CROWD'
export const RESET_FILTER = 'RESET_FILTER'
export const ENSURE_STRUCT = 'ENSURE_STRUCT'
export const GENERATE = 'GENERATE'
export const GENERATE_MARKET = 'GENERATE_MARKET'
export const GENERATE_DIVERSION = 'GENERATE_DIVERSION'
export const SHAPE_POINT = 'SHAPE_POINT'
export const SWITCH_TAB = 'SWITCH_TAB'

export const GENERATE_DONE = 'GENERATE_DONE'

export const UPDATE_SWITCH = 'UPDATE_SWITCH'


export const UPDATEUSERINFO = 'UPDATEUSERINFO'


