import React, {Component} from 'react';

export default class FilterButton extends Component {
  render() {
    const { title,group,opacity,select,condition } = this.props
    const len = group.length
    const enable = condition.enable

    const style = {opacity}
    
    return (
      <div className='filter-button' style={style}>
        <p className='filter-button-title'>{title}</p>
        {group.map((button,idx)=>{
          let style = {}
          if (len == 2) {
            if (idx==0) {
              style={'borderBottomLeftRadius':'2px','borderTopLeftRadius':'2px','borderRight':'0px'}
            }else{
              style={'borderBottomRightRadius':'2px','borderTopRightRadius':'2px'}
            }
          } else {
            switch (idx) {
              case 0:
                style={'borderBottomLeftRadius':'2px','borderTopLeftRadius':'2px'}
                break;
              case (len-1):
                style={'borderBottomRightRadius':'2px','borderTopRightRadius':'2px'}
                break;
              default:
                style={'borderLeft':'0px','borderRight':'0px'}
                break;
            }            
          }
          return (
            <div key={idx} className={'filter-radio pointer '+((enable && condition.selected==idx)?'filter-radio-selected':'')} style={style} onClick={()=>{
              if (enable){
                select(idx)
              }
            }}>
              <span>{button.cn}</span>
            </div>
          )
        })}
      </div>
    )    
  }
}

FilterButton.defaultProps = {
  select:()=>{},
  opacity: 1
}